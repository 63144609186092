import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import CheckInOutPopup from 'library/common/commonComponents/Popups/CheckInOutPopup/CheckInOutPopup';
import EmployeeTimePopup from 'library/common/commonComponents/Popups/EmployeeTimePopup/EmployeeTimePopup';
import AbsencePopup from 'library/common/commonComponents/Popups/AbsencePopup/AbsencePopup';
import CoronaPopup from 'library/common/commonComponents/Popups/CoronaPopup/CoronaCapturePopup';
import cn from 'classnames';

import styles from './TopbarCheckAbsence.module.scss';

const TopbarCheckAbsence = ({ kitaId, kitaColor, modules, curCheckInOutActiveForParents }) => {
  const [isCheckInOutPopupOpen, setIsCheckInOutPopupOpen] = useState(false);
  const [isEmployeeTimePopupOpen, setIsEmployeeTimePopupOpen] = useState(false);
  const [isAbsencePopupOpen, setIsAbsencePopupOpen] = useState(false);
  const [isCoronaPopupOpen, setIsCoronaPopupOpen] = useState(false);
  const user = useSelector(state => state.userReducer);

  const absenceModule = modules.find(element => {
    return element.moduleKey === 'absence';
  });

  const checkInOutModule = modules.find(element => {
    return element.moduleKey === 'checkinout';
  });

  const emplyeeCheckInOutModule = modules.find(element => {
    return element.moduleKey === 'employee_checkinout';
  });

  const coronaTestModule = modules.find(element => {
    return element.moduleKey === 'corona_test';
  });

  if (user.lastName === 'Caterer') {
    return null;
  }

  const showKidCheckInOut =
    !!checkInOutModule &&
    checkInOutModule !== 'undefined' &&
    checkInOutModule.activeStatus &&
    checkInOutModule.activeStatus === true &&
    curCheckInOutActiveForParents;

  const showEmployeeCheckInOut =
    (!!emplyeeCheckInOutModule && emplyeeCheckInOutModule.activeStatus && user.employee) || user.superAdminStatus;

  return (
    <div className={styles.popupsContainer}>
      {coronaTestModule &&
        coronaTestModule !== 'undefined' &&
        coronaTestModule.activeStatus &&
        coronaTestModule.activeStatus === true && (
          <div
            className={styles.action}
            style={{ backgroundColor: kitaColor }}
            onClick={() => setIsCoronaPopupOpen(true)}
          >
            <i className='fa fa-certificate' />
          </div>
        )}

      {showKidCheckInOut && (
        <div
          className={styles.action}
          style={{ backgroundColor: kitaColor }}
          onClick={() => setIsCheckInOutPopupOpen(true)}
        >
          <i className='fa fa-address-book' />
        </div>
      )}
      {absenceModule &&
        absenceModule !== 'undefined' &&
        absenceModule.activeStatus &&
        absenceModule.activeStatus === true && (
          <div
            className={styles.action}
            style={{ backgroundColor: kitaColor }}
            onClick={() => setIsAbsencePopupOpen(true)}
          >
            <i className='fa fa-medkit' />
          </div>
        )}

      {showEmployeeCheckInOut && (
        <div
          className={cn(styles.action, styles.employeeTimeBtn)}
          style={{ backgroundColor: kitaColor }}
          onClick={() => setIsEmployeeTimePopupOpen(true)}
        >
          <i className='fa fa-clock-o' />
        </div>
      )}

      {isEmployeeTimePopupOpen && (
        <EmployeeTimePopup isOpened closePopup={() => setIsEmployeeTimePopupOpen(false)} />
      )}

      {isCheckInOutPopupOpen && (
        <CheckInOutPopup
          isOpened
          closePopup={() => setIsCheckInOutPopupOpen(false)}
          showKidCheckInOut={showKidCheckInOut}
        />
      )}
      {isAbsencePopupOpen && (
        <AbsencePopup
          user={user}
          showBottomNotification={showBottomNotification}
          isOpened
          closePopup={() => setIsAbsencePopupOpen(false)}
          kitaId={kitaId}
        />
      )}
    </div>
  );
};

export default TopbarCheckAbsence;
