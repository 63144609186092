import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';

import styles from './usersListPopup.module.scss';

export default function UsersListPopup({ users, isOpened, closePopup, title }) {
  const { t } = useTranslation();
  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      header={title}
      footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
    >
      <div className={styles.container}>
        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
          {users.map(user => {
            const userId = user.userId || user.id;

            return (
              <Link to={`/profile/${userId}`} key={userId} className={styles.item}>
                <div className={styles.itemAvatar}>
                  <ProfileAvatar image={user} id={userId} withoutLink />
                </div>

                <div className={styles.itemInfo}>
                  <div className={cn(styles.itemName, 'notranslate')}>
                    {user.name || `${user.firstName} ${user.lastName}`}
                    <span className={styles.attendCountInfo}>
                      {user.manualAttendCount > 1 ?
                        `${t('Popup.With')} ${user.manualAttendCount - 1} ${t('Popup.Persons')}`
                        : ''
                      }
                    </span>
                  </div>
                  {user.status && <div className={styles.itemStatus}>{user.status}</div>}
                </div>
              </Link>
            );
          })}
        </Scrollbars>
      </div>
    </Popup>
  );
}

UsersListPopup.defaultProps = {
  users: [],
};
