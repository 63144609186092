import React, { useState } from 'react';
import PT from 'prop-types';

import useCalendarExportEvents from 'library/common/commonHooks/useCalendarExportEvents';
import useCalendarExportEventsGroup from 'library/common/commonHooks/useCalendarExportEventsGroup';
import {
  getUserExports,
  removeUserExport,
  addUserExport,
  importUserEvents,
  getExportsGroup,
  addExportGroup,
  importGroupEvents,
} from 'library/api/calendar';
import CalendarExportPopup from './CalendarExportPopup/CalendarExportPopup';
import CalendarImportPopup from './CalendarImportPopup/CalendarImportPopup';
import styles from './calendarImportExport.module.scss';

const CalendarImportExport = ({ user, filters, reloadEvents, groupInfo }) => {
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [isImportPopupOpen, setIsImportPopupOpen] = useState(false);

  const { exports, deleteUserExport, saveUserExport, importUserFile } = useCalendarExportEvents(
    getUserExports,
    removeUserExport,
    addUserExport,
    importUserEvents,
    groupInfo,
  );
  const {
    exportsGroup,
    deleteExportGroup,
    saveExportGroup,
    importGroupFile,
  } = useCalendarExportEventsGroup(
    getExportsGroup,
    removeUserExport,
    addExportGroup,
    importGroupEvents,
    groupInfo,
  );

  return (
    <div className={styles.actionsContainer}>
      <div className={styles.action} onClick={() => setIsExportPopupOpen(true)}>
        <i className='fa fa-download' />
      </div>
      {(user.administrationAccess || user.superAdminStatus || groupInfo?.groupAdminStatus) && (
        <div className={styles.action} onClick={() => setIsImportPopupOpen(true)}>
          <i className='fa fa-upload' />
        </div>
      )}
      {isExportPopupOpen && (
        <CalendarExportPopup
          exports={groupInfo === undefined ? exports : exportsGroup}
          deleteExport={groupInfo === undefined ? deleteUserExport : deleteExportGroup}
          saveExport={groupInfo === undefined ? saveUserExport : saveExportGroup}
          filters={filters}
          isOpened
          closePopup={() => setIsExportPopupOpen(false)}
        />
      )}
      {isImportPopupOpen && (
        <CalendarImportPopup
          importFile={groupInfo === undefined ? importUserFile : importGroupFile}
          reloadEvents={reloadEvents}
          isOpened
          closePopup={() => setIsImportPopupOpen(false)}
        />
      )}
    </div>
  );
};

CalendarImportExport.propTypes = {
  filters: PT.arrayOf(PT.object).isRequired,
  reloadEvents: PT.func.isRequired,
  groupInfo: PT.objectOf(PT.any).isRequired,
};

export default CalendarImportExport;
