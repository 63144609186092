import React, { useState, useCallback, useRef, useEffect } from 'react';
import { sanitize } from 'dompurify';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { isAllowedToSendMessages } from 'library/api/chat';
import { getEmployeeIds } from 'library/api/kita';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Card from 'library/common/commonComponents/Card';
import Loader from 'library/common/commonComponents/Loader';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import { isImage } from 'library/utilities/files';

import Popup from '../../Popups/Popup';
import ChatMessagesViewHeader from './ChatMessagesViewFrames/ChatMessagesViewHeader';
import ChatMessagesViewBody from './ChatMessagesViewFrames/ChatMessagesViewBody';
import ChatMessagesViewFooter from './ChatMessagesViewFrames/ChatMessagesViewFooter/ChatMessagesViewFooterContainer';

import styles from './chatMessagesView.module.scss';

export default function ChatMessagesView({
  userId,
  activeChat,
  openChatId,
  isLoading,
  onLeave,
  onDelete,
  onSendMessage,
  onAddUser,
  onLoadMore,
  isKitaVirtual,
  isNotOnlyPublicKitaMember,
  currentWatsonLang,
  modules,
  user,
  onClickReturn,
}) {
  const [messageId, setMessageId] = useState('');
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isScrollDown, setIsScrollDown] = useState(true);
  const [height, setHeight] = useState(0);
  const container = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [confirmIsOpened, setConfirmIsOpened] = useState(false);

  const [employeesPerKita, setEmployeesPerKita] = useState({});

  if (employeesPerKita[activeChat.kitaId] && activeChat.users) {
    activeChat.users.forEach(user => {
      if(!user) return;
      user.employee = employeesPerKita[activeChat.kitaId].includes(user.id);
    });
  }

  let list = [];
  activeChat.messages.forEach(message => {
    list = [...list, ...message.files];
  });
  const allImages = list.filter(file => isImage(file.fileId));

  const handleEditOpen = (chatIdToEdit, id) => {
    setMessageId(id);
    setConfirmIsOpened(true);
  };

  const handleDelete = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHeight(container.current ? container.current.scrollHeight - container.current.scrollTop : 0);
    setIsScrollDown(false);
    await onDelete(activeChat.id, messageId);
    setIsSubmitting(false);
    setConfirmIsOpened(false);
  };

  const onScrollMessages = async () => {
    if (container.current && !container.current.scrollTop) {
      setIsScrollDown(false);
      setIsMessagesLoading(true);
      await onLoadMore(activeChat.id);
      setIsMessagesLoading(false);
    }
  };

  const measuredRef = useCallback(
    node => {
      if (node !== null) {
        const nodeHeight = node.scrollHeight;
        if (nodeHeight > 525) {
          if (nodeHeight >= height || isScrollDown) {
            node.scrollTop = isScrollDown ? nodeHeight : nodeHeight - height;
          }
          container.current = node;
          container.current.addEventListener('scroll', onScrollMessages);
          setHeight(node.scrollHeight);
        }
      }
    },
    // eslint-disable-next-line
    [activeChat.messages && activeChat.messages.length, isScrollDown, activeChat.id],
  );

  useEffect(() => {
    if (activeChat.kitaId) {
      getEmployeeIds(activeChat.kitaId).then(employeeIdRes => {
        const employeeIds = employeeIdRes.data;
        setEmployeesPerKita({ ...employeesPerKita, [activeChat.kitaId]: employeeIds });
      });
    }
  }, [activeChat.kitaId]);

  useEffect(() => {
    return () => {
      if (container.current) {
        container.current.removeEventListener('scroll', onScrollMessages);
      }
      setHeight(0);
      setIsScrollDown(true);
    };
    // eslint-disable-next-line
  }, [activeChat.id]);

  const handleSendMessage = (selectedChatId, newText, newFiles, setProgress) => {
    setIsScrollDown(true);
    return onSendMessage(selectedChatId, newText, newFiles, setProgress);
  };
  const isAnonymous = activeChat.anonymousCreator;

  return isLoading ? (
    <Loader />
  ) : (
    <div className={openChatId === 0 ? styles.noChatSelected : styles.chatSelected}>
      <div
        className={styles.wrapper}
        onClick={() => {
          if (location) {
            location.state = { selectedChatId: 0 };
          }
          onClickReturn();
        }}
      >
        <div className={styles.button}>
          <i className='fa fa-chevron-left' />
        </div>
        <div className={styles.text}>{t('Chat.Back To All Messages')}</div>
      </div>
      <Card
        className={styles.cardContainer}
        title={
          activeChat.watsonLanguage === currentWatsonLang ||
          ((activeChat.watsonLanguage === 'null' || activeChat.watsonLanguage === null) &&
            currentWatsonLang === 'de') ? (
            activeChat.subject
          ) : (
            <WithWatsonTranslate
              data={{ text: activeChat.subject, entityId: activeChat.id, entityType: 'chat' }}
              Component={({ html }) => (
                <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
              )}
            />
          )
        }
        titleClassName={styles.bodyTitle}
        titleChilderen={
          <ChatMessagesViewHeader
            isAnonymous={isAnonymous}
            userId={userId}
            chatId={activeChat.id}
            userList={activeChat.users || []}
            onLeave={onLeave}
            onAddUser={onAddUser}
            usersInChat={activeChat.users}
            isKitaVirtual={isKitaVirtual}
          />
        }
      >
        <div className={styles.container} ref={measuredRef}>
          {isMessagesLoading && <Loader />}
          {activeChat.messages &&
            activeChat.messages.length > 0 &&
            activeChat.messages.map((message, index, messagesArray) => (
              <ChatMessagesViewBody
                key={message.id}
                {...message}
                allImages={allImages}
                chatId={activeChat.id}
                isLastChat={messagesArray.length - 1 === index}
                user={message.user}
                isAnonymous={isAnonymous}
                isSuggestionBox={activeChat.suggestionBox}
                messageId={message.id}
                userId={userId}
                onEdit={handleEditOpen}
                isKitaVirtual={isKitaVirtual}
                currentWatsonLang={currentWatsonLang}
              />
            ))}
        </div>
        {!isKitaVirtual &&
          !isAnonymous &&
          isNotOnlyPublicKitaMember &&
          isAllowedToSendMessages(modules, user, activeChat.users || []) && (
            <ChatMessagesViewFooter chatId={activeChat.id} onSendMessage={handleSendMessage} />
          )}

        <Popup
          isOpened={confirmIsOpened}
          closePopup={() => setConfirmIsOpened(false)}
          size='small'
          header={<Trans i18nKey='Chat.Confirm message deletion' />}
          footer={
            <div className={styles.buttonsContainerConfirm}>
              <div className={styles.buttonCancelConfirm}>
                <Button
                  onClick={() => {
                    setConfirmIsOpened(false);
                  }}
                >
                  {t('Chat.Cancel')}
                </Button>
              </div>
              <div className={styles.buttonDeleteConfirm}>
                <ButtonWithLoader onClick={handleDelete} type='primary' isLoading={isSubmitting}>
                  {t('Chat.Delete')}
                </ButtonWithLoader>
              </div>
            </div>
          }
        >
          <div className={styles.confirmText}>{t('Chat.Do you really want to delete')}</div>
        </Popup>
      </Card>
    </div>
  );
}
