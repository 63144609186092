import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { useDropdown } from 'library/common/commonHooks/useDropdown';
import useNotificationsLoading from 'library/common/commonHooks/useNotificationsLoading';
import useChatMessages from 'library/common/commonHooks/useChatMessages';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import DropdownNotifications from './topbarNotificationsFrames/Dropdowns/DropdownNotifications';
import DropdownMessages from './topbarNotificationsFrames/Dropdowns/DropdownMessages';

import styles from './topbarNotifications.module.scss';

export default function TopbarNotifications({
  setUnreadMessagesCountsKitas,
  unreadMessagesCountsKitas,
  setSelectedChatId,
  history,
  setIsCreateNewChatOpened,
  setIsCreateNewAbsenceOpened,
  activeKita,
  kitas,
  updateCountOfNotifications,
  updateCountOfTaxReceipt,
  unreadNotificationsCount,
  user,
  kitaColor,
  modules,
  timeRangeAbsenceInfo,
  onDeactiveTimeRangeAbsence,
}) {
  const absenceModule = !!modules.find(m => m.moduleKey === 'absence')?.activeStatus;
  const shouldDisplayFilterButton = !activeKita?.kitaTypeTranslations?.includes('Safe2Connect') && absenceModule;
  const [isOpenedNotification, setIsOpenedNotification] = useState(false);
  const [unreadNotificationsActiveKita, setUnreadNotificationsActiveKita] = useState(0);
  const [activeTab, setActiveTab] = useState(undefined);

  useEffect(() => {
    if (activeKita && unreadNotificationsCount) {
      setUnreadNotificationsActiveKita(
        unreadNotificationsCount[activeKita.kitaId] &&
          unreadNotificationsCount[activeKita.kitaId].count,
      );
    }
  }, [activeKita, unreadNotificationsCount]);

  useEffect(() => {
    setActiveTab(shouldDisplayFilterButton ? 0 : undefined);
  }, [shouldDisplayFilterButton]);

  const { isOpened: mailsIsOpened, DropdownWrapper: DropdownMailsWrapper } = useDropdown(
    DropdownMessages,
  );

  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsOpenedNotification(false),
  });

  const {
    isLoading,
    notifications,
    onNotificationsScroll,
    markAllAsRead,
    refScroll,
    readNotification,
  } = useNotificationsLoading({
    isOpened: isOpenedNotification,
    kitas,
    updateCountOfNotifications,
    updateCountOfTaxReceipt,
    activeKita,
    setUnreadMessagesCountsKitas,
  });

  const page = 1;
  const chatId = 0;
  const isNotification = true;
  const { isLoadingChatlists, handleMarkAllAsRead } = useChatMessages({
    chatId,
    page,
    history,
    unreadMessagesCountsKitas,
    setUnreadMessagesCountsKitas,
    isNotification,
    isNotificationOpened: mailsIsOpened,
    activeKita,
    activeTab,
    isTopBar: true,
  });

  let stylesItem = styles.item;

  if (window.location.hostname.toLowerCase().includes('bvf4u.safe2connect.org')) {
    stylesItem = styles.blueItem;
  }
  if (window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org')) {
    stylesItem = styles.brownItem;
  }
  const kitaIdsToHideChatFunction = [1423];

  return (
    <div className={styles.notifications}>
      <div ref={outsideEventContainerRef}>
        <div
          onClick={() => {
            setIsOpenedNotification(!isOpenedNotification);
          }}
        >
          <div
            className={cn(stylesItem, isOpenedNotification && styles.active)}
            style={{ backgroundColor: kitaColor }}
          >
            <i className='fa fa-bell' />
            <DropdownNotifications
              isOpened={isOpenedNotification}
              unreadNotificationsCount={unreadNotificationsActiveKita}
              isLoading={isLoading}
              notifications={notifications}
              onNotificationsScroll={onNotificationsScroll}
              markAllAsRead={markAllAsRead}
              refScroll={refScroll}
              readNotification={readNotification}
              user={user}
            />
            {unreadNotificationsActiveKita > 0 && (
              <div className={styles.unreadNotifications}>{unreadNotificationsActiveKita}</div>
            )}
          </div>
        </div>
      </div>

      <DropdownMailsWrapper
        render={dropdown => (
          <div
            style={
              window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org') ||
              kitaIdsToHideChatFunction.includes(activeKita.kitaId)
                ? { display: 'none' }
                : { backgroundColor: kitaColor }
            }
            className={cn(stylesItem, mailsIsOpened && styles.active)}
          >
            <i className='fa fa-envelope' />
            {dropdown}
            {unreadMessagesCountsKitas[activeKita.kitaId] > 0 && (
              <div className={styles.unreadNotifications}>
                {unreadMessagesCountsKitas[activeKita.kitaId]}
              </div>
            )}
          </div>
        )}
        closeOnClick
        isLoadingChatlists={isLoadingChatlists}
        isKitaVirtual={activeKita.virtual}
        onChatClick={setSelectedChatId}
        setIsCreateNewChatOpened={setIsCreateNewChatOpened}
        setIsCreateNewAbsenceOpened={setIsCreateNewAbsenceOpened}
        timeRangeAbsenceInfo={timeRangeAbsenceInfo}
        onDeactiveTimeRangeAbsence={onDeactiveTimeRangeAbsence}
        kitas={kitas}
        currentWatsonLang={user.currentWatsonLang}
        user={user}
        modules={modules}
        handleMarkAllAsRead={handleMarkAllAsRead}
        setUnreadMessagesCountsKitas={setUnreadMessagesCountsKitas}
        unreadMessagesCountsKitas={unreadMessagesCountsKitas}
        activeKita={activeKita}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        shouldDisplayFilterButton={shouldDisplayFilterButton}
      />
    </div>
  );
}
