import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URL = {
  groups: '/groups/',
  adminGroups: '/groups/admin',
  group: '/group/',
  modules: 'group/modules/',
  module: 'group/module/',
  groupsSecurity: '/groups/security/',
  permission: '/group-permission/',
  groupModules: '/group/modules/',
  groupNotification: '/group/notification/',
  groupPost: '/group/post/',
  groupUserMembership: '/group/user/membership/',
  groupSearch: '/group/search/',
  groupForChildDropdown: '/groups/child',
  allGroups: '/currentKita/allGroups/',
  groupListForUser: '/groupListForUser/',
  kitabygroupid: '/kitabygroupid/',
};

const graphql =
  // eslint-disable-next-line
  'groupName,colourCode,groupDescription,groupLogoUrl,memberStatus,id,logoUrl,groupLogoAspect,groupLogoXCoordinate,groupLogoYCoordinate,groupLogoWidth,globalPublicGroup,defaultGroup';

export const getGroupsPayload = {
  method: 'get',
  url: URL.groups,
  settings: {
    params: {
      graphql,
    },
  },
};

export const getGroupsPayloadWithBazaarGroup = {
  method: 'get',
  url: URL.groupListForUser,
};

export const getGroups = () => {
  const { method, url, settings } = getGroupsPayload;
  return axios[method](url, settings);
};

export const getGroupsForChildDropdown = () => {
  return axios.get(URL.groupForChildDropdown);
};

export const getAllGroups = () => {
  return axios.get(URL.allGroups);
};

export const getGroupsWhereAdmin = () => axios.get(URL.adminGroups);

export const getGroupById = id => axios.get(URL.groups + id);

export const getKitaByById = id => axios.get(URL.kitabygroupid + id);

export const getGroupsByName = (name, filter = true) =>
  axios
    .get(URL.groupSearch + name, {
      params: {
        filter,
        graphql,
      },
    })
    .then(r => r.data);

export const loadGroupMembers = (id, data, params) =>
  axios.post(
    `${URL.groups}${id}/members`,
    {
      ...data,
      graphql:
        // eslint-disable-next-line
        'email,firstName,groupAdminStatus,groupRegistrationStatus,id,lastName,hideUser,logoUrl,profileLogoAspect,profileLogoWidth,profileLogoXCoordinate,profileLogoYCoordinate',
    },
    params,
  );

export const patchGroup = payload => axios.put(URL.groups, payload);

export const deleteGroup = (id, password) => axios.delete(URL.groups + id, { data: { password } });

export const deletePosts = (id, password, dateLimit = new Date().toISOString().slice(0, 10)) =>
  axios.patch(URL.groups + id, { password }, { params: { dateLimit } });

export const getModules = () => axios.get(URL.modules);

export const putModule = data => axios.put(URL.module, data);

export const getGroupPermission = id => axios.get(URL.permission + id);

export const putGroupPermission = payload => axios.put(URL.permission, payload);

export const createNewGroupPermission = payload => {
  return axios.post('/new-group-permission', payload).then(res => {
    switch (res.status) {
      case 200:
        return {
          status: res.status,
          result: true,
          reason: 'NewPermission.Ok',
          data: res.data,
        };
      case 208:
        return {
          status: res.status,
          result: false,
          reason: 'NewPermission.Permission Key Duplication',
        };
      default:
        return false;
    }
  });
};

export const updateGroupPermissionTranslation = payload => {
  return axios.put('/update-group-permission-translation', payload);
};

export const putGroupSecurity = payload => axios.put(URL.groupsSecurity, payload);

export const getGroupModules = groupIds => axios.post(URL.groupModules, groupIds);

export const putGroupNotification = (id, status) =>
  axios.put(URL.groupNotification + id, { notificationStatus: status });

export const putPostVisibility = (id, status) =>
  axios.put(URL.groupPost + id, { postVisibility: status });

export const putUserMembership = id => axios.put(URL.groupUserMembership + id);

export const uploadGroupImage = async ({
  id,
  image,
  imageAspect,
  imageWidth,
  type,
  setProgress,
}) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('imageAspect', imageAspect);
  formData.append('imageWidth', imageWidth);
  formData.append('type', type);
  formData.append('xCoordinate', 25);
  formData.append('yCoordinate', 25);
  return axios.post(`${URL.group}${id}/image`, formData, {
    onUploadProgress: progressEvent => {
      setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
    },
  });
};

export const editGroupImage = ({ id, imageAspect, imageWidth, type, xCoordinate, yCoordinate }) => {
  const formData = new FormData();
  formData.append('imageAspect', imageAspect);
  formData.append('imageWidth', imageWidth);
  formData.append('type', type);
  formData.append('xCoordinate', xCoordinate);
  formData.append('yCoordinate', yCoordinate);
  return axios.put(`${URL.group}${id}/image`, formData);
};

export const deleteGroupImage = (id, type) => {
  const formData = new FormData();
  formData.append('type', type);
  return axios.delete(`${URL.group}${id}/image`, { data: formData });
};

export const setGroupGallerySnippet = ({ groupId, galleryId, isLatest }) =>
  axios.put(`${URL.group}${groupId}/gallery/${galleryId}/snippet?latest=${isLatest}`);

export const reportPost = (id, groupID, kitaId) => {
  return axios.post(`${URL.groups}reportPost/${id}/${groupID}/${kitaId}`);
};

export const getGroupsOfKita = ({
  page,
  sort,
  searchValue,
  kitaId,
  visibility,
  onlyPortfolioGroups,
  size = 50,
}) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/kitas/${kitaId}/${onlyPortfolioGroups}/groups?visibility=${!!visibility}${searchValue &&
      '&searchKeyword=' + searchValue}`,
    {
      filter: [],
      graphql:
        // eslint-disable-next-line
        'id,groupName,privacy,defaultGroup,colourCode,groupDescription,groupLogoAspect,groupLogoWidth,logoUrl,groupLogoXCoordinate,groupLogoYCoordinate,groupMembers[id,user[id,firstName,lastName,userName],groupRole[adminRole]]',
      page,
      size,
      sort: [sort.name, sort.direction],
    },
  );

export const getGroupsOfInstitutionForGroupAdminOrInstitutionAdmin = groupId => {
  return axios.get(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/kitas/getGroupsOfInstitutionForGroupAdminOrInstitutionAdmin`,
    {
      params: {
        groupId,
      },
    },
  );
};

export const setGlobalGroupConfiguration = payload =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/kita/global/group/config`, payload);

export const setGlobalPublicGroup = (groupId, payload) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/users/current/global-public-group/${groupId}/consent`,
    payload,
  );

export const addUsersToGroup = ({ groupId, payload }) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/groups/${groupId}/users/bulkAdd`, payload);

export const getGroupHolidayCares = groupId => axios.get(`/groups/${groupId}/holidaycares`);

export const updateGroupHolidayCares = ({ groupId, groupHolidayCares }) =>
  axios.post(`/groups/${groupId}/holidaycares`, groupHolidayCares);

export const exportGroupMemberEmailList = groupId =>
  axios.post(`/groups/${groupId}/group-members/email-list/export`);

export const toggleUserGroupFavoriteStatus = (groupId, userId, favoriteStatus) => {
  return axios.put(`group/${groupId}/user/${userId}/isFavorite/${favoriteStatus}`);
};

export const getPublicGroupList = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/public/public-groups`);

export const getPublicGroupListOutside = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/public/public-groups`);

export const getPublicGroupListMember = () => axios.get('/public-groups-member');

export const getAWOUserGroups = groupId => axios.get(`/group/${groupId}/awo-users`);
