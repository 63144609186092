import { SET_CHATS_LIST, SET_CHATS_LIST_TOP_BAR } from '../commonConstants/chatListPopupConstants';

const initialState = {
  chatsList: [],
  chatsListTopBar: [],
};
const chatListPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS_LIST:
      return {
        ...state,
        chatsList: action.payload,
      };
    case SET_CHATS_LIST_TOP_BAR:
      return {
        ...state,
        chatsListTopBar: action.payload,
      };
    default:
      return state;
  }
};
export default chatListPopupReducer;
