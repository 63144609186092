import { SET_CHATS_LIST, SET_CHATS_LIST_TOP_BAR } from '../commonConstants/chatListPopupConstants';

export const setChatsList = payload => dispatch => {
  dispatch({
    type: SET_CHATS_LIST,
    payload,
  });
};

export const setChatsListTopBar = payload => dispatch => {
  dispatch({
    type: SET_CHATS_LIST_TOP_BAR,
    payload,
  });
};
