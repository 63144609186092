import React, { useState, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { patchGroup } from 'library/api/groups';
import Label from 'library/common/commonComponents/Label';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Input from 'library/common/commonComponents/Inputs/Input';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import Button from 'library/common/commonComponents/Buttons/Button';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { GroupContext } from 'modules/Group/groupModule';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { updatePermanentStatusGroup } from 'library/api/posts';
import { isValidDate } from 'library/utilities/date';
import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './basicFrame.module.scss';

export function BasicFrame({
  setDeleteMode,
  showBottomNotificationFromProps,
  patchGroupFromProps,
}) {
  const user = useSelector(state => state.userReducer);

  const groupContext = useContext(GroupContext);
  const patchGroupInfo = groupContext.patchGroup;
  const { groupInfo } = groupContext;
  const { t } = useTranslation();

  const [errors, setErrors] = useState({
    groupName: '',
    groupDescription: '',
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [value, setValue] = useState(groupInfo);
  const [permanentPopupOpened, setPermanentPopupOpened] = useState(false);
  const [allowMessageToGroup, setAllowMessageToGroup] = useState(
    groupInfo.allowMessageToGroup != null ? groupInfo.allowMessageToGroup : true,
  );
  const [permanentDateLimit, setPermanentDateLimit] = useState();
  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsColorPickerVisible(false),
  });

  const patchSettings = data => {
    setValue({ ...value, ...data });
  };
  const onSubmit = () => {
    if (isDisabled) {
      return;
    }
    if (validateForm()) {
      const payload = {
        colourCode: value.colorCode,
        groupDescription: value.groupDescription,
        groupName: value.groupName,
        id: value.groupId,
        approveComment: value.approveComment,
        allowMessageToGroup: allowMessageToGroup,
      };
      setIsDisabled(true);
      patchGroupFromProps(payload).then(res => {
        switch (res.status) {
          case 200:
            patchGroupInfo(value);
            showBottomNotificationFromProps(t('GroupBody.Settings.Saved'));
            setIsDisabled(false);
            break;

          case 208:
            setErrors({
              colourCode: '',
              groupName: t('GroupBody.Settings.Name has already been taken'),
              groupDescription: '',
            });
            setIsDisabled(false);
            break;

          default:
            break;
        }
      });
    }
  };

  const validateForm = () => {
    let isError = false;
    let errorsTemplate = {
      colourCode: '',
      groupName: '',
      groupDescription: '',
    };
    if (!value.groupName.trim().length) {
      errorsTemplate = {
        ...errorsTemplate,
        ...{ groupName: t('GroupBody.Settings.Space name field is required') },
      };
      isError = true;
    } else if (value.groupName.trim().length < 2) {
      errorsTemplate = {
        ...errorsTemplate,
        ...{ groupName: t('CreateGroup.Name should contain at least 2 characters') },
      };
      isError = true;
    }

    setErrors(errorsTemplate);
    if (!isError) {
      return true;
    } else {
      return false;
    }
  };
  const onDrag = c => {
    patchSettings({ colorCode: c.hex });
  };

  if (!value || (!value.groupId && value.groupId !== 0)) return null;
  return (
    <div className={styles.container}>
      <div className={styles.inputGroup}>
        <Label type='input'>{t('GroupBody.Settings.Name')} *</Label>
        <div className={styles.inputWithColorPicker} ref={outsideEventContainerRef}>
          <Input
            error={errors.groupName !== ''}
            value={value.groupName}
            placeholder={t('GroupBody.Settings.Space name')}
            maxLength={45}
            onChange={e => patchSettings({ groupName: e.target.value })}
          />
          {errors.groupName !== '' && (
            <div className={styles.error_msg}>
              <InputErrorMsg errorMsg={errors.groupName} />
            </div>
          )}

          <div className={styles.colorPicker}>
            <div
              className={styles.label}
              onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
            >
              <div className={styles.current} style={{ background: value.colorCode }} />
            </div>
            {isColorPickerVisible && (
              <ColorPickerButton
                color={value.colorCode}
                className={styles.pickerComponent}
                onClick={onDrag}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.inputGroup}>
        <Label type='input'>{t('GroupBody.Settings.Description')}</Label>
        <TextArea
          placeholder={t('GroupBody.Settings.space description')}
          value={value.groupDescription}
          error={errors.groupDescription !== ''}
          onChange={e => patchSettings({ groupDescription: e.target.value })}
          maxLength={255}
        />
        {errors.groupDescription !== '' && (
          <div className={styles.error_msg}>
            <InputErrorMsg errorMsg={errors.groupDescription} />
          </div>
        )}
      </div>
      <div className={styles.inputGroup}>
        <CheckBox
          className={styles.checkbox}
          name={t('GroupBody.Settings.AllowMessageToGroup')}
          isChecked={allowMessageToGroup || false}
          onChange={() => setAllowMessageToGroup(!allowMessageToGroup)}
        />
        {user.superAdminStatus && (
          <CheckBox
            className={styles.checkbox}
            name={t('GroupBody.Settings.ApproveComment')}
            isChecked={value.approveComment || false}
            onChange={() => patchSettings({ approveComment: !value.approveComment })}
          />
        )}
      </div>

      <Popup
        isOpened={permanentPopupOpened}
        closePopup={() => setPermanentPopupOpened(false)}
        header={t('GroupBody.Settings.Permanent status')}
        footer={
          <div className={styles.buttonsContainer}>
            {t('GroupBody.Settings.Removes the permanent status of posts until the selected date')}
            <br />
            <br />
            <div className={styles.buttonSubmit}>
              <Input
                type='date'
                onChange={e => {
                  setPermanentDateLimit(e.target.value);
                }}
                value={permanentDateLimit}
              />
              {errors.permanentDateLimit && errors.permanentDateLimit !== '' && (
                <div className={styles.error_msg}>
                  <InputErrorMsg errorMsg={errors.permanentDateLimit} />
                </div>
              )}
              <br />
            </div>
            <div className={styles.buttonCancel}>
              <Button
                type='primary'
                onClick={() => {
                  if (!isValidDate(permanentDateLimit)) {
                    setErrors({
                      ...errors,
                      permanentDateLimit: t('GroupBody.Settings.Valid date is required'),
                    });
                    return;
                  } else {
                    setErrors({
                      ...errors,
                      permanentDateLimit: '',
                    });
                  }
                  setPermanentPopupOpened(false);
                  updatePermanentStatusGroup(groupInfo.groupId, permanentDateLimit);
                  showBottomNotificationFromProps(t('GroupBody.Settings.Saved'));
                }}
              >
                {t('GroupBody.Settings.Submit')}
              </Button>
              &nbsp;
              <Button onClick={() => setPermanentPopupOpened(false)}>
                {t('GroupBody.Settings.Cancel')}
              </Button>
            </div>
          </div>
        }
      />

      <div className={styles.footer}>
        <ButtonWithLoader type='primary' onClick={onSubmit} isLoading={isDisabled}>
          {t('GroupBody.Settings.Save')}
        </ButtonWithLoader>

        <div className={styles.deleteButtons}>
          {groupInfo.groupDeletionStatus && (
            <Button onClick={() => setDeleteMode('group')} type='danger'>
              {t('GroupBody.Settings.Delete')}
            </Button>
          )}
          <Button onClick={() => setDeleteMode('posts')} type='danger'>
            {t('GroupBody.Settings.Delete Posts')}
          </Button>
          <Button onClick={() => setPermanentPopupOpened(true)} type='danger'>
            {t('GroupBody.Settings.Permanent status')}
          </Button>
        </div>
      </div>
    </div>
  );
}

BasicFrame.defaultProps = {
  patchGroupFromProps: patchGroup,
  groupInfo: {
    userList: [],
  },
};

const mapDispatchToProps = {
  showBottomNotificationFromProps: showBottomNotification,
};
export default connect(
  null,
  mapDispatchToProps,
)(BasicFrame);
