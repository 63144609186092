import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URLConfig = `${config.API_BASE_URI}${getPort(8091)}/api`;
export const URLModules = `${config.API_BASE_URI}${getPort(8080)}/api`;

export const getKitaModules = () => axios.get(URLModules + `/kitas/current/modules`);

export const changeActivate = (moduleId, moduleAction) =>
  axios.put(URLModules + `/kitas/current/modules/${moduleId}/${moduleAction}`);

export const getCredentialModules = () =>
  axios.get(URLModules + `/kitas/current/modules/credential`);

export const setAsDefault = module =>
  axios.put(URLModules + `/kitas/current/modules/${module.moduleId}/status`, null, {
    params: {
      groupModuleStatus: module.groupModuleStatus,
      userModuleStatus: module.userModuleStatus,
    },
  });

export const getConfigureTaskModule = kitaId =>
  axios.get(URLConfig + `/kita/${kitaId}/task-configuration`);

export const setConfigureTaskModule = (configId, payload) =>
  axios.put(URLConfig + `/task-configuration/${configId}`, payload);

export const addModuleInDatabaseToTenant = data =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/addmoduleindatabasetocurrenttenant/`,
    data,
  );

export const activateChatModuleForAllKitasOfTenant = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/activatechatmoduleforallkitasoftenant/`);

export const getMailSubjectConfig = () =>
  axios.get(URLModules + `/kitas/global/mail-subject`);

export const updateMailSubjectConfig = (subject) =>
  axios.put(URLModules + `/kitas/global/mail-subject`, {subject});

export const getAddKitaAdminsToKidPortfolioGroups = () => axios.get(URLModules + `/modulesconfig/addKitaAdminsToKidPortfolioGroups/get`);
export const setAddKitaAdminsToKidPortfolioGroups = (enabled) => axios.post(URLModules + `/modulesconfig/addKitaAdminsToKidPortfolioGroups`, {}, { params: { enabled } });
