import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getChatInfo,
  getChatMeassges,
  addNewChat,
  leaveChat,
  editMessage,
  deleteMessage,
  sendMessage,
  sendAutoMessage,
  addUser,
  markMessageAsRead,
  leaveChatAll,
  postEmployeeAbsenceTimeRange,
  getEmployeeAbsenceTimeRange,
  disableEmployeeAbsenceTimeRange,
  markAllMessageAsRead,
} from 'library/api/chat';
import { setChatsList, setChatsListTopBar } from '../commonActions/chatListPopupActions';
import {
  setTimeRangeAbsence,
  setTimeRangeAbsenceLoading,
  setTimeRangeAbsenceEndLoading,
  disableTimeRangeAbsenceLoading,
} from 'library/common/commonActions/timerangeAbsenceAction';
import store from 'main/store/configureStore';
import { useSelector, useDispatch } from 'react-redux';

export default function useChatMessages({
  chatId,
  page,
  history,
  unreadMessagesCountsKitas,
  setUnreadMessagesCountsKitas,
  isNotification,
  isNotificationOpened,
  showBottomNotification,
  activeKita,
  forceReload = false,
  timeRangeAbsenceInfo,
  activeTab,
  isTopBar
}) {
  const [activeChat, setActiveChat] = useState({ messages: [] });
  const [isLoadingChatlists, setIsLoadingChatlists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(+page);
  const isMessagesLoading = useRef(false);
  const isMessagesEnded = useRef(false);
  const messages = useRef([]);
  const [newMetadata, setNewMetadata] = useState({ type: '', data: {} });
  const dispatch = useDispatch();
  const chatsList = useSelector(state => isTopBar ? state.chatListPopupReducer.chatsListTopBar : state.chatListPopupReducer.chatsList);

  const [filteredUserIds, setFilteredUserIds] = useState([]);
  const [filteredByText, setFilteredByText] = useState('');

  const { t } = useTranslation();
  useEffect(() => {
    setIsLoading(true);
    setIsLoadingChatlists(true);
    if (isNotificationOpened == null || isNotificationOpened === true) {
      getChatInfo(chatId, currentPage, isNotification, filteredUserIds, filteredByText, activeTab)
        .then(res => {
          if (res.status === 200) {
            setActiveChat(res.data.selectedChat || { messages: [] });
            if (isTopBar) {
              setChatsListTopBar(res.data.chatsPage.content)(dispatch);
            }
            else {
              setChatsList(res.data.chatsPage.content)(dispatch);
            }

            messages.current = res.data.selectedChat ? res.data.selectedChat.messages : [];
            setTotalPages(res.data.chatsPage.totalPages);
          }
          setIsLoading(false);
          setIsLoadingChatlists(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsLoadingChatlists(false);
          history.push(history.location.pathname);
        });
    }
    // eslint-disable-next-line
  }, [chatId, currentPage, isNotificationOpened, filteredUserIds, filteredByText, activeTab]);

  useEffect(() => {
    return () => {
      isMessagesLoading.current = false;
      isMessagesEnded.current = false;
    };
  }, [activeChat.id]);

  const handleSetActiveChat = clickedChat => {
    if (!clickedChat.id) {
      setActiveChat(clickedChat);
      return;
    }

    if (activeChat.id === clickedChat.id && !forceReload) {
      return;
    }

    setIsLoading(true);

    getChatMeassges(clickedChat.id)
      .then(res => {
        if (res.status === 200) {
          const isRead = clickedChat.lastMessage.read;
          setActiveChat({
            ...clickedChat,
            messages: res.data.content,
            lastMessage: { ...clickedChat.lastMessage, read: true },
          });
          setChatsList(
            chatsList.map(chat =>
              chat.id === clickedChat.id
                ? { ...clickedChat, lastMessage: { ...clickedChat.lastMessage, read: true } }
                : chat,
            ),
          )(dispatch);

          messages.current = res.data.content;
          if (!isRead) {
            setUnreadMessagesCountsKitas({
              ...unreadMessagesCountsKitas,
              [activeKita.kitaId]: unreadMessagesCountsKitas[activeKita.kitaId] - 1,
            });
          }
        }
        setIsLoading(false);
      })
      .catch(res => {
        setIsLoading(false);
      });
  };

  const handleMarkAllAsRead = chats => {
    //for loop over totalPages
    markAllMessageAsRead();

    // update UI by setting lastMessage.read to true for every chat
    setChatsList(
      chats.map(c2 => {
        return {
          ...c2,
          lastMessage: { ...c2.lastMessage, read: true },
        };
      }),
    );
    setUnreadMessagesCountsKitas({
      ...unreadMessagesCountsKitas,
      [activeKita.kitaId]: 0,
    });
  };

  const handleAddNewChat = (newChatInfo, files, setProgress) => {
    setIsLoading(true);
    return addNewChat(newChatInfo, activeKita.kitaId, files, setProgress)
      .then(res => {
        if (res.status === 200) {
          if (isNotification) {
            history.push('/chat/1');
          }
          setChatsList(res.data.chatsPage.content)(dispatch);
          setActiveChat(res.data.selectedChat || { messages: [] });
          setTotalPages(res.data.chatsPage.totalPages);
          messages.current = res.data.selectedChat.messages;
        }
        setIsLoading(false);
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          showBottomNotification(t(`Chat.${error.response.data.message}`), { isFail: true });
        }
        setIsLoading(false);
        throw new Error();
      });
  };

  const handleAddNewAbsence = (startDateFormat, endDateFormat, absenceMessage) => {
    store.dispatch(setTimeRangeAbsenceLoading());
    return postEmployeeAbsenceTimeRange({
      startDate: startDateFormat,
      endDate: endDateFormat,
      absenceMessage,
    })
      .then(({ data }) => {
        store.dispatch(setTimeRangeAbsence(data));
        showBottomNotification(t(`Chat.CreateAbsenceSuccess`), { isFail: false });
      })
      .catch(() => {
        showBottomNotification(t(`Chat.CreateAbsenceError`), { isFail: true });
      })
      .finally(() => {
        store.dispatch(setTimeRangeAbsenceEndLoading());
      });
  };

  const handleDeactiveTimeRangeAbsence = () => {
    if (timeRangeAbsenceInfo.timeRangeAbsence) {
      store.dispatch(setTimeRangeAbsenceLoading());
      disableEmployeeAbsenceTimeRange(timeRangeAbsenceInfo.timeRangeAbsence.id)
        .then(() => {
          store.dispatch(disableTimeRangeAbsenceLoading());
        })
        .finally(() => {
          store.dispatch(setTimeRangeAbsenceEndLoading());
        });
    }
  };

  const handleLeaveChat = (leaveUserid, leaveChatId) => {
    setIsLoading(true);
    return leaveChat(leaveChatId)
      .then(res => {
        if (res.status === 200) {
          setChatsList(res.data.chatsPage.content)(dispatch);
          setActiveChat(res.data.selectedChat || res.data.chatsPage.content[0] || { messages: [] });
          setTotalPages(res.data.chatsPage.totalPages);
          setCurrentPage(1);
          messages.current = res.data.selectedChat ? res.data.selectedChat.messages : [];
        }
        setIsLoading(false);
      })
      .catch(res => {
        console.log(res);
        setIsLoading(false);
      });
  };

  const handleEditMessage = (
    selectedChatId,
    messageId,
    newText,
    files,
    setProgress,
    deletedFileIds,
  ) => {
    return editMessage(selectedChatId, messageId, newText, files, setProgress, deletedFileIds)
      .then(res => {
        if (res.status === 200) {
          setChatsList(
            chatsList.map(chat => {
              if (chat.id === selectedChatId) {
                return res.data;
              }
              return chat;
            }),
          )(dispatch);

          const newMessages = activeChat.messages.slice();
          const index = activeChat.messages.findIndex(item => item.id === messageId);
          if (index !== -1) {
            newMessages.splice(index, 1, {
              ...activeChat.messages[index],
              ...res.data.messages[index],
              edited: true,
              message: newText,
            });
          }
          messages.current = newMessages;
          setActiveChat({ ...res.data, messages: newMessages });
        }
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleDeleteMessage = (selectedChatId, messageId) => {
    return deleteMessage(selectedChatId, messageId)
      .then(res => {
        if (res.status === 200) {
          setChatsList(
            chatsList.map(chat => {
              if (chat.id === selectedChatId) {
                return res.data;
              }
              return chat;
            }),
          )(dispatch);
          const newMessages = activeChat.messages.slice();
          const index = activeChat.messages.findIndex(item => item.id === messageId);
          if (index !== -1) {
            newMessages.splice(index, 1);
          }
          messages.current = newMessages;
          setActiveChat({ ...res.data, messages: newMessages });
        }
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleLeaveChatAll = async () => {
    try {
      const res = await leaveChatAll();
      if (res.status === 200) {
        setChatsList([])(dispatch);
        setActiveChat({ messages: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendMessage = async (selectedChatId, newText, files, setProgress) => {
    try {
      const res = await sendMessage(selectedChatId, newText, files, setProgress);
      const autoRes = await sendAutoMessage(selectedChatId, newText);
      const chatDatas = [];
      if (res.data) {
        chatDatas.push(res.data);
      }
      if (autoRes.data?.length) {
        chatDatas.push(...autoRes.data);
      }
      if (chatDatas.length) {
        const chatData = chatDatas[chatDatas.length - 1];
        setChatsList(
          chatsList.map(chat => {
            if (chat.id === selectedChatId) {
              return chatData;
            }
            return chat;
          }),
        )(dispatch);

        let lastId = 0;
        if (activeChat?.messages?.length) {
          lastId = activeChat.messages[activeChat.messages.length - 1].id;
        }
        const newMessages = activeChat.messages.concat(
          chatData.messages.filter(x => x.id > lastId),
        );
        messages.current = newMessages;
        setActiveChat({ ...chatData, messages: newMessages });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMoreMessages = activeChatId => {
    if (!isMessagesLoading.current && !isMessagesEnded.current) {
      isMessagesLoading.current = true;
      return getChatMeassges(activeChatId, messages.current[0].id)
        .then(res => {
          if (res.status === 200) {
            isMessagesEnded.current = res.data.last;
            const messagesTmp = messages.current.slice();
            const newMessages = res.data.content.reduceRight((result, item) => {
              result.unshift(item);
              return result;
            }, messagesTmp);
            messages.current = newMessages;
            setActiveChat({ ...activeChat, messages: newMessages });
          }
          isMessagesLoading.current = false;
        })
        .catch(res => {
          console.log(res);
          isMessagesLoading.current = false;
        });
    }
  };

  const handleAddUser = (selectedChatId, newUsers) => {
    return addUser(selectedChatId, newUsers)
      .then(res => {
        if (res.status === 200) {
          setChatsList(
            chatsList.map(chat => {
              if (chat.id === selectedChatId) {
                return { ...chat, users: chat.users.concat(newUsers) };
              }
              return chat;
            }),
          )(dispatch);

          setActiveChat({ ...activeChat, users: activeChat.users.concat(newUsers) });
        }
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleReceiveNewMetadata = ({ data, metadata }) => {
    setNewMetadata({ type: metadata.type, data });
  };

  const handleReceiveNewMessage = () => {
    let shouldUpdate = true;
    const newChatsList = chatsList.map(chat => {
      if (chat.id === newMetadata.data.chatId) {
        shouldUpdate = chat.lastMessage.read;
        return {
          ...chat,
          lastMessage: { ...newMetadata.data, read: activeChat.id === newMetadata.data.chatId },
        };
      }
      return chat;
    });
    setChatsList(newChatsList)(dispatch);
    if (activeChat.id === newMetadata.data.chatId && !isNotification) {
      markMessageAsRead([newMetadata.data.chatId]);
      shouldUpdate = false;
      const newMessages = activeChat.messages ? activeChat.messages.concat(newMetadata.data) : [];
      messages.current = newMessages;
      setActiveChat({
        ...activeChat,
        messages: newMessages,
        lastMessage: { ...newMetadata.data, read: true },
      });
    }
    if (shouldUpdate && isNotification) {
      setUnreadMessagesCountsKitas({
        ...unreadMessagesCountsKitas,
        [activeKita.kitaId]: unreadMessagesCountsKitas[activeKita.kitaId] + 1,
      });
    }
  };

  const handleReceiveMessageDelete = () => {
    const { deletedMessage, chatLastMessage } = newMetadata.data;
    if (activeChat.id === deletedMessage.chatId && !isNotification) {
      const newMessages = activeChat.messages ? activeChat.messages.slice() : [];
      const index = newMessages.findIndex(item => item.id === deletedMessage.id);
      if (index !== -1) {
        newMessages.splice(index, 1);
      }
      messages.current = newMessages;
      setActiveChat({
        ...activeChat,
        messages: newMessages,
        lastMessage: chatLastMessage,
      });
    }
    const newChatsList = chatsList.map(chat => {
      if (chat.id === deletedMessage.chatId) {
        return {
          ...chat,
          lastMessage: chatLastMessage,
        };
      }
      return chat;
    });
    setChatsList(newChatsList)(dispatch);
  };

  const handleReceiveMessageEdit = () => {
    if (activeChat.id === newMetadata.data.chatId && !isNotification) {
      const newMessages = activeChat.messages ? activeChat.messages.slice() : [];
      const index = newMessages.findIndex(item => item.id === newMetadata.data.id);
      if (index !== -1) {
        newMessages.splice(index, 1, { ...newMetadata.data });
      }
      messages.current = newMessages;
      setActiveChat({
        ...activeChat,
        messages: newMessages,
        lastMessage:
          newMetadata.data.id === activeChat.lastMessage.id
            ? { ...newMetadata.data, read: true }
            : activeChat.lastMessage,
      });
    }
    const newChatsList = chatsList.map(chat => {
      if (chat.id === newMetadata.data.chatId) {
        return {
          ...chat,
          lastMessage:
            newMetadata.data.id === chat.lastMessage.id
              ? { ...newMetadata.data, read: chat.lastMessage.read }
              : chat.lastMessage,
        };
      }
      return chat;
    });
    setChatsList(newChatsList)(dispatch);
  };

  useEffect(() => {
    switch (newMetadata.type) {
      case 'chat-new-message':
        return handleReceiveNewMessage();
      case 'chat-updated-message':
        return handleReceiveMessageEdit();
      case 'chat-deleted-message':
        return handleReceiveMessageDelete();
      case 'chat-user-joined':
      case 'chat-user-left':
      default:
    }
    // eslint-disable-next-line
  }, [newMetadata]);

  useEffect(() => {
    import('library/api/socket').then(({ addSocketListener }) =>
      addSocketListener(({ data, metadata }) => {
        handleReceiveNewMetadata({ data, metadata });
      }),
    );
  }, []);

  return {
    isKitaVirtual: activeKita.virtual,
    isLoadingChatlists,
    isLoading,
    activeChat,
    totalPages,
    currentPage,
    setCurrentPage: newPage => setCurrentPage(newPage),
    handleSetActiveChat,
    handleAddNewChat,
    handleAddNewAbsence,
    handleDeactiveTimeRangeAbsence,
    timeRangeAbsenceInfo,
    handleLeaveChat,
    handleEditMessage,
    handleDeleteMessage,
    handleLeaveChatAll,
    handleSendMessage,
    handleAddUser,
    handleLoadMoreMessages,
    handleMarkAllAsRead,
    setFilteredUserIds: ids => setFilteredUserIds(ids),
    setFilteredByText: text => setFilteredByText(text),
  };
}
