import React, { useEffect, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { store } from 'main/store/configureStore';
import { GROUP_MODULES } from 'library/common/commonConstants/groupConstants';
import FileManager from 'library/common/commonComponents/FileManager/FileManagerContainer';
import Gallery from 'library/common/commonComponents/Gallery/GalleryContainer';
import Bazaar from 'library/common/commonComponents/Bazaar/BazaarContainer';
import Surveys from 'library/common/commonComponents/Surveys';
import ParentSurveys from 'library/common/commonComponents/ParentSurveys';
import CoronaTest from 'library/common/commonComponents/CoronaTest';
// import CheckInOut from 'library/common/commonComponents/CheckInOut';
import Portfolio from 'library/common/commonComponents/Portfolio';
import Signature from 'library/common/commonComponents/Signature';
import Wiki from 'library/common/commonComponents/Wiki';
import { isBazaarGroup } from 'library/utilities/groups';
import Whiteboard from 'library/common/commonComponents/Whiteboard';
import GroupStream from '../groupFrames/GroupStream';
import GroupAbsence from '../groupFrames/GroupAbsence';
import GroupCheckinout from '../groupFrames/GroupCheckinout';
import GroupCalendar from '../groupFrames/GroupCalendar/GroupCalendarContainer';
import GroupWorkingHours from '../groupFrames/GroupWorkingHours/GroupWorkingHoursContainer';
import KitalinoInterface from 'library/common/commonComponents/KitalinoInterface';
import GroupTasks from '../groupFrames/GroupTasks';
import { defaultGroupState } from '../groupConstants';
import FoodOrder from '../../FoodOrder/FoodOrderContainer';

export default function useGroupInfo(groupData, match, activeKita) {
  const { t } = useTranslation();
  const user = store.getState().userReducer;
  let defaultMenuItems = [
    {
      id: 'stream',
      title: t('ProfileBody.LeftNav.Stream'),
      faIcon: 'fa-bars',
      to: `${match.url}/stream`,
      component: GroupStream,
      props: {
        activeKita,
        userLastName: user?.lastName,
        isUserAMember: !(
          groupData.showPosts &&
          !groupData.groupAdminStatus &&
          !groupData.showCancelMembership
        ),
      },
    },
  ];

  if (isBazaarGroup(groupData)) {
    defaultMenuItems = [];
  }

  const [groupInfo, dispatch] = useReducer(groupInfoReducer, defaultGroupState);
  useEffect(() => {
    dispatch({ type: 'updateGroupInfo', data: groupData });
    updateMenuItems();
    // eslint-disable-next-line
  }, [groupData]);

  const patchGroup = data => {
    dispatch({ type: 'updateGroupInfo', data });
    updateMenuItems();
  };

  const [menuItems, setMenuItems] = useState([]);
  const faIcon = item => GROUP_MODULES.find(module => module.moduleKey === item.moduleKey);
  const updateMenuItems = () => {
    if (groupData.showModules || groupData.showPosts) {
      let menuItemsToAdd = defaultMenuItems.concat(
        groupData.groupModuleList
          .filter(mod => {
            if (
              (mod.moduleKey === 'working_hours' &&
                !user.administrationAccess &&
                !groupData.groupAdminStatus &&
                !user.employee) ||
              mod.moduleKey === 'translations' ||
              mod.moduleKey === 'image_download' ||
              mod.moduleKey === 'employee_portfolio' ||
              mod.moduleKey === 'chat' ||
              mod.moduleKey === 'tax_consultant' ||
              ((mod.moduleKey === 'checkinout' || mod.moduleKey === 'absence') &&
                !user.administrationAccess &&
                !groupData.groupAdminStatus &&
                !user.employee) ||
              (mod.moduleKey === 'portfolio' &&
                !user.administrationAccess &&
                !groupData.groupAdminStatus &&
                !user.employee) ||
              (mod.moduleKey === 'gallery' && isBazaarGroup(groupData)) ||
              (mod.moduleKey === 'corona_test' &&
                !user.administrationAccess &&
                !groupData.groupAdminStatus) ||
              (mod.moduleKey === 'employee_checkinout' && !user.administrationAccess)
            ) {
              return false;
            }

            return true;
          })
          .map(item => {
            if (item.moduleKey === 'absence') {
              return {
                moduleKey: 'absence',
                title: t('ProfileBody.LeftNav.Absence'),
                faIcon: 'fa-medkit',
                to: `${match.url}/absence`,
                component: GroupAbsence,
                props: {
                  isUserAMember: !(
                    groupData.showPosts &&
                    !groupData.groupAdminStatus &&
                    !groupData.showCancelMembership
                  ),
                },
              };
            }

            return {
              moduleKey: item.moduleKey,
              title: t(getGroupMenuModuleTranslation(item.moduleKey, groupData)),
              faIcon: faIcon(item) ? faIcon(item).icon : undefined,
              to:
                item.moduleKey === 'gallery'
                  ? `${match.url}/gallery`
                  : item.moduleKey === 'bazaar'
                  ? `${match.url}/bazaar`
                  : item.moduleKey === 'parent_survey'
                  ? `${match.url}/parent-survey`
                  : `${match.url}/${item.moduleName.toLowerCase()}`,
              component: getModuleComponent(item.moduleKey),
              props: {
                id: match.params.id,
                isUserAMember: !(
                  groupData.showPosts &&
                  !groupData.groupAdminStatus &&
                  !groupData.showCancelMembership
                ),
                activeKita,
              },
            };
          }),
      );
      setMenuItems(menuItemsToAdd);
    }
  };

  return { groupInfo, patchGroup, menuItems };
}

export function getModuleComponent(moduleId, groupId) {
  switch (moduleId) {
    case 'files':
      return FileManager;
    case 'calendar':
      return GroupCalendar;
    case 'working_hours':
      return GroupWorkingHours;
    case 'absence':
      return GroupAbsence;
    case 'checkinout':
      return GroupCheckinout;
    case 'gallery':
      return Gallery;
    case 'survey':
      return Surveys;
    case 'parent_survey':
      return ParentSurveys;
    case 'food_order':
      return FoodOrder;
    case 'portfolio':
      return Portfolio;
    case 'bazaar':
      return Bazaar;
    case 'tasks':
      return GroupTasks;
    case 'signature':
      return Signature;
    case 'wiki':
      return Wiki;
    case 'corona_test':
      return CoronaTest;
    case 'whiteboard':
      return Whiteboard;
    case 'kitalino_interface':
      return KitalinoInterface;
    default:
      return () => <div />;
  }
}

function getGroupMenuModuleTranslation(moduleKey, groupData) {
  switch (moduleKey) {
    case 'absence':
      return 'Administration.ModulesSection.Absence reporting';
    case 'image_download':
      return 'Administration.ModulesSection.Download images';
    case 'files':
      return 'Administration.ModulesSection.Files';
    case 'calendar':
      return 'Administration.ModulesSection.Calendar';
    case 'working_hours':
      return 'Administration.ModulesSection.Dienstplan';
    case 'gallery':
      return 'Administration.ModulesSection.Gallery';
    case 'survey':
      return 'Administration.ModulesSection.Surveys';
    case 'portfolio':
      return 'Administration.ModulesSection.Portfolio';
    case 'tasks':
      return 'Administration.ModulesSection.Tasks';
    case 'translations':
      return 'Administration.ModulesSection.Dynamic translator';
    case 'suggestion_box':
      return 'DashboardSuggestionBox.title';
    case 'parent_survey':
      if (
        groupData.groupId === 6 ||
        groupData.groupId === 188 ||
        groupData.groupId === 189 ||
        groupData.groupId === 190
      ) {
        return 'Mitarbeiterumfrage';
      }
      return 'ParentSurvey.MouleSelection.Parent Survey';
    case 'food_order':
      return 'FoodOrder.ModulesSection.Signature';
    case 'tax_consultant':
      return 'TaxConsultant.ModuleSelection.TaxConsultant';
    case 'bazaar':
      return 'Bazaar.ModuleSelection.Title';
    case 'signature':
      return 'Signature.ModulesSection.Signature';
    case 'checkinout':
      return 'Administration.ModulesSection.Checkinout';
    case 'corona_test':
      return 'Administration.ModulesSection.Corona test';
    case 'chat':
      return 'Administration.ModulesSection.Chat';
    case 'whiteboard':
      if (groupData.groupId === 53757803 || groupData.groupName === 'Igelgruppe') {
        return 'Gruppenbuch';
      }
      return 'Administration.ModulesSection.Whiteboard';
    case 'kitalino_interface':
      return 'Administration.ModulesSection.KitalinoInterface';
    default:
      return moduleKey;
  }
}

export function groupInfoReducer(state, action) {
  switch (action.type) {
    case 'updateGroupInfo':
      return { ...state, ...action.data };
    default:
      return state;
  }
}
