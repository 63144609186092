import React from 'react';
import { connect } from 'react-redux';
import { isAwoWW } from 'library/api/tenantConfig';
import cn from 'classnames';

import styles from './kitaPanelWrapper.module.scss';

export function KitaPanelWrapper({ children, isOpened, className }) {
  return (
    <div
      className={cn(
        styles.kitaPanelWrapper,
        { [styles.kitaPanelWrapperWide]: (!isOpened || isAwoWW()) },
        className,
      )}
    >
      {children}
    </div>
  );
}

export default connect(state => ({
  isOpened: state.kitaReducer.isOpened,
}))(KitaPanelWrapper);
