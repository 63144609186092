import React, { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { sanitize } from 'dompurify';

import EditMediaPopup from 'library/common/commonComponents/Popups/EditMediaPopup';
import Tooltip from 'library/common/commonComponents/Tooltip';
import Button from 'library/common/commonComponents/Buttons/Button';
import useGallery from 'library/common/commonHooks/useGallery';
import { isImage, isVideo } from 'library/utilities/files';
import useCachedImage from 'library/common/commonHooks/useCachedImage';
import VideoPreview from 'library/common/commonComponents/VideoPreview';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';

import { useTranslation } from 'react-i18next';
import styles from './feedItemGallery.module.scss';

export default function FeedItemGallery({
  group,
  text,
  gallery,
  files,
  isEditing,
  setIsEditing,
  id,
  updateFeedById,
  userId,
  isTranslationAllowed,
  isBazaar,
  canDownloadImage,
}) {
  const [showAllFiles, setShowAllFiles] = useState(files.length <= 4);
  const { Gallery, openGallery } = useGallery(isBazaar);
  const { t } = useTranslation();

  const openFile = index => openFileHandler({ index, files, openGallery });

  const GetCachedImage = ({ file, index }) => {
    const { CachedImage } = useCachedImage({
      originalSrc: file.path,
      props: {
        alt: file.fileId,
        className: styles.galleryImagesImage,
        onClick: () => openFile(index),
      },
    });

    return CachedImage;
  };

  const { history } = useRouter();
  const onOpenGalleryBtnClick = () =>
    onOpenGalleryBtnClickHandler({
      history,
      groupId: group ? group.groupId : 0,
      galleryId: gallery.id,
      userId,
      isBazaar,
    });

  const editText = ({ description }) => updateFeedById(id, { text: description });

  useEffect(() => {
    const handleContextMenu = (event) => {
      if (!canDownloadImage && event.target.tagName === 'IMG') {
        event.preventDefault()
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [canDownloadImage]);

  return (
    <div className={styles.gallery}>
      {isEditing && (
        <EditMediaPopup
          isOpened={isEditing}
          closePopup={() => setIsEditing(false)}
          description={text || ''}
          editMedia={editText}
        />
      )}

      <Gallery />
      <div className={styles.galleryImages}>
        {files.map((file, index) => {
          const shouldShowImage = isImage(file.fileId);
          const shouldShowVideo = isVideo(file.fileId);

          return (
            (showAllFiles || index < 4) && (
              <Tooltip
                key={file.id}
                text={file.fileId}
                isVisibleCondition={(showAllFiles || index !== 3) && files.length > 1}
              >
                <div className={styles.galleryImagesImageWrapper}>
                  {shouldShowVideo && (
                    <VideoPreview
                      file={file}
                      onClick={() => openFile(index)}
                      className={styles.galleryImagesImage}
                    />
                  )}
                  {shouldShowImage && <GetCachedImage index={index} file={file} />}
                  {!showAllFiles && index === 3 && (
                    <div
                      className={styles.galleryImagesImageOverlay}
                      onClick={() => setShowAllFiles(true)}
                    >
                      + {files.length - 3}
                    </div>
                  )}
                </div>
              </Tooltip>
            )
          );
        })}
      </div>
      <div className={styles.galleryInfo}>
        {files.length === 1 && (
          <>
            <div className={styles.galleryInfoImageTitle}>
              {isBazaar ? files[0].description : files[0].fileId}
            </div>
            {isBazaar && (
              <>
                <div>
                  {t('Bazaar.Price')}: {files[0].bazaarItemInfo.price.toFixed(2)} €
                  <i className='fa fa-shopping-cart' />
                </div>
              </>
            )}
          </>
        )}

        {files &&
          files.length === 1 &&
          typeof files[0] !== 'undefined' &&
          files[0].description &&
          files[0].description != null && (
            <div className={styles.galleryInfoDescription}>
              {isTranslationAllowed ? (
                <WithWatsonTranslate
                  data={{
                    text: files[0].description,
                    entityId: id,
                    entityType: 'gallery description',
                  }}
                  Component={({ html }) => (
                    <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                  )}
                />
              ) : (
                files[0].description
              )}
            </div>
          )}

        <div className={styles.galleryInfoTitle}>
          <strong>{isBazaar ? t('Bazaar.Bazaar') : t('Gallery.Gallery')}: </strong>
          {gallery && (
            <>
              {isTranslationAllowed ? (
                <WithWatsonTranslate
                  data={{ text: gallery.galleryName, entityId: id, entityType: 'gallery' }}
                  Component={({ html }) => (
                    <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                  )}
                />
              ) : (
                gallery.galleryName
              )}
            </>
          )}
        </div>
        <Button type='default' size='sm' onClick={onOpenGalleryBtnClick}>
          {isBazaar ? t('Bazaar.Open Bazaar') : t('Gallery.Open gallery')}
        </Button>
      </div>
    </div>
  );
}

export function openFileHandler({ index, files, openGallery }) {
  openGallery(files, { index });
}

export function onOpenGalleryBtnClickHandler({ history, groupId, galleryId, userId, isBazaar }) {
  if (groupId) {
    if (isBazaar) {
      history.push(`/groups/${groupId}/bazaar?${galleryId}`);
    } else {
      history.push(`/groups/${groupId}/gallery?${galleryId}`);
    }
  } else {
    if (isBazaar) {
      history.push(`/profile/${userId}/bazaar?${galleryId}`);
    } else {
      history.push(`/profile/${userId}/gallery?${galleryId}`);
    }
  }
}
