import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Card from 'library/common/commonComponents/Card';
import ListItem from 'library/common/commonComponents/ListItems/ListItem';
import ListItemCounter from 'library/common/commonComponents/ListItems/ListItemCounter';
import CalendarSettings from 'library/common/commonComponents/Calendar/CalendarFrames/CalendarSettings';
// eslint-disable-next-line max-len
import TaskDetailContainer from 'library/common/commonComponents/TaskListsMain/TaskListsMainFrames/TaskLists/TaskListsFrames/TaskList/TaskListFrames/TaskDetailView/TaskDetailContainer';
import { isMobile } from 'react-device-detect';
import GroupBodyModules from './GroupBodyFrames/GroupBodyModules';
import GroupBodySettings from './GroupBodyFrames/GroupBodySettings';
import GroupBodySecurity from './GroupBodyFrames/GroupBodySecurity';
import GroupBodyMembers from './GroupBodyFrames/GroupBodyMembers';

import styles from './groupBody.module.scss';
import { getUnreadKidAbsenceCount } from '../../../../api/user';
import GroupTasks from 'modules/Group/groupFrames/GroupTasks';

GroupBody.defaultProps = {
  groupInfo: {},
  menuItems: [],
};

export function GroupBody({ groupInfo, menuItems, isNotInKidsMode, absenceReported }) {
  const user = useSelector(state => state.userReducer);
  const [groupMenuCollapsed, setGroupMenuCollapsed] = useState(isMobile);
  const [absenceCounter, setAbsenceCounter] = useState(0);

  if (user.lastName === 'Caterer') {
    menuItems = menuItems.filter(item => item?.id === "stream" || item?.moduleKey === "food_order");
  }


  const ABSENCE_COUNTER_UPDATE_FREQ = 120000; // frequence of absence counter updates in milliseconds

  const updateCount = () => {
    getUnreadKidAbsenceCount(groupInfo.groupId).then(res => {
      setAbsenceCounter(res.data);
    });
  };

  function absenceCounterUpdateIsNeeded() {
    if (groupInfo && groupInfo.groupModuleList && Array.isArray(groupInfo.groupModuleList)) {
      const absenceModule = groupInfo.groupModuleList.filter(
        module => module.moduleKey === 'absence',
      );
      if (absenceModule.length > 0) {
        if (
          user.administrationAccess ||
          user.employee ||
          user.superAdminStatus ||
          groupInfo.groupAdminStatus
        ) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (absenceCounterUpdateIsNeeded()) {
      updateCount();
    }
  }, [absenceReported]);

  useEffect(() => {
    if (absenceCounterUpdateIsNeeded()) {
      window.addEventListener('onAbsenceConfirm', () => {
        updateCount();
      });
      let intervalId = setInterval(updateCount, ABSENCE_COUNTER_UPDATE_FREQ);
      return () => {
        window.removeEventListener('onAbsenceConfirm', null);
        clearInterval(intervalId);
      };
    }
  }, []);

  return (
    <Col>
      <Row>
        <div className={styles.wrapper}>
          <div className={styles.menu}>
            {isNotInKidsMode && (
              <Card
                title={
                  <div>
                    <Trans i18nKey='SpaceMenu' />{' '}
                    {groupMenuCollapsed ? (
                      <i className='fa fa-chevron-down' />
                    ) : (
                      <i className='fa fa-chevron-up' />
                    )}
                  </div>
                }
                onTitleClick={() => {
                  setGroupMenuCollapsed(!groupMenuCollapsed);
                }}
              >
                {(groupInfo.showModules || groupInfo.showPosts) &&
                  groupMenuCollapsed === false &&
                  menuItems.map(menuItem => {
                    if (menuItem.moduleKey === 'absence') {
                      return (
                        <ListItemCounter
                          theme='light'
                          className={styles.listItem}
                          key={menuItem.to}
                          isNavLink
                          {...menuItem}
                          count={absenceCounter}
                          showCounterIfZero={false}
                        />
                      );
                    }

                    return (
                      <ListItem
                        theme='light'
                        className={styles.listItem}
                        key={menuItem.to}
                        isNavLink
                        {...menuItem}
                      />
                    );
                  })}
              </Card>
            )}
          </div>

          <div className={styles.body}>
            <Switch>
              <Redirect
                exact
                from='/groups/:id'
                to={
                  user.lastName === 'Caterer'
                    ? '/groups/:id/essensbestellung'
                    : '/groups/:id/stream'
                }
              />
              <Route
                path='/groups/:id/tasks/:taskId'
                render={({ match, history }) => (
                  <TaskDetailContainer group={groupInfo} match={match} history={history} />
                )}
              />
              <Route
                path='/groups/:id/task-lists/:taskListId'
                render={({ match, history }) => (
                  <>
                    <GroupTasks
                      groupInfo={groupInfo}
                      isOpenedByDefault={[match.params.taskListId]}
                    />
                  </>
                )}
              />
              <Route
                path='/groups/:id/task-in-lists/:taskListId/:taskId'
                render={({ match, history }) => (
                  <>
                    <GroupTasks
                      groupInfo={groupInfo}
                      isOpenedByDefault={[match.params.taskListId]}
                      openedTasksByDefault={[match.params.taskId]}
                    />
                  </>
                )}
              />
              {menuItems.map(menuItem => (
                <Route
                  key={menuItem.to}
                  path={menuItem.to}
                  exact
                  render={({ match, history }) =>
                    menuItem.component ? (
                      <menuItem.component
                        groupInfo={groupInfo}
                        {...menuItem.props || {}}
                        match={match}
                        history={history}
                      />
                    ) : null
                  }
                />
              ))}
              {groupInfo.groupId && groupInfo.groupAdminStatus && (
                <>
                  <Route path='/groups/:id/settings' component={GroupBodySettings} />
                  <Route path='/groups/:id/modules' component={GroupBodyModules} />
                  <Route path='/groups/:id/security' component={GroupBodySecurity} />
                  <Route path='/groups/:id/members' component={GroupBodyMembers} />
                  <Route path='/groups/:id/calendarSettings' component={CalendarSettings} />
                </>
              )}
            </Switch>
          </div>
        </div>
      </Row>
    </Col>
  );
}

const mapStateToProps = state => ({
  absenceReported: state.absenceReportReducer.absenceReported,
});

export default connect(mapStateToProps)(GroupBody);
