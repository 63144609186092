import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';



import styles from './usersFrameImportPopup.module.scss';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Button from 'library/common/commonComponents/Buttons/Button';

export default function ImportToKitaportalByInterface({ showBottomNotification }) {
  const { t } = useTranslation();

  const data = [
    {
      KIND_ID_EXTERN: "K36649",
      KIND_VORNAME: "Test",
      KIND_NACHNAME: "Kind",
      KIND_GEBDATUM: "01.01.2017",
      KIND_GESCHLECHT: "u",
      KIND_STAATSANGEHOERIGKEIT: "",
      KIND_FAMILIENSPRACHE: "Deutsch",
      KIND_KONFESSION: ""
    }
  ];

  const [checkedState, setCheckedState] = useState(
    new Array(data.length).fill(false) // Initialize all checkboxes as unchecked
  );

  // Handler to update the state when a checkbox is toggled
  const handleCheckboxChange = (index) => {
    const updatedCheckedState = checkedState.map((item, idx) =>
      idx === index ? !item : item // Toggle the checkbox state for the selected row
    );
    setCheckedState(updatedCheckedState);
  };


  return (
    <>
      <h2>{t('Administration.Import to kitaportal by interface')}</h2>
      <br />
      <div>{t('Administration.Import to kitaportal by interface description')}</div>
      <br />
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>Vorname</th>
              <th>Nachname</th>
            </tr>
          </thead>
          <tbody>
            {data.map((obj, index) => (
              <tr key={obj.KIND_ID_EXTERN}>
                <td>
                  <Checkbox
                    isChecked={checkedState[index]} // Bind the checkbox state to the state variable
                    onChange={() => handleCheckboxChange(index)} // Update state on change
                  />
                </td>
                <td>{obj.KIND_ID_EXTERN}</td>
                <td>{obj.KIND_VORNAME}</td>
                <td>{obj.KIND_NACHNAME}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Button>{t('Administration.Start import')}</Button>
      </div>
    </>
  );
}
