import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import styles from './input.module.scss';

Input.defaultProps = {
  type: 'text',
  onFaIconClick: () => { },
};

export default function Input({
  type,
  placeholder,
  faIcon,
  onFaIconClick,
  className,
  value,
  onChange,
  onRenderFocus,
  error,
  disabled,
  readOnly,
  onFocus,
  onBlur,
  checked,
  onClick,
  min,
  max,
  maxLength,
  step,
  tabIndex,
  onKeyDown,
  id,
  style,
  containerStyle,
  accept,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current && onRenderFocus) ref.current.focus();
  }, []);

  const checktimeval = () => {
    const timeval = ref.current.value;
    if (!(timeval > '07:00' && timeval < '18:00')) {
      console.log('Please enter time <7 a.m and >6 p.m');
    }

    if (type === 'date') {
      const minDate = '1970-01-01';
      const maxDate = '2050-12-31';

      if (timeval && timeval < minDate) {
        onChange({ target: { value: '1970-01-01' } });
      } else if (timeval > maxDate) {
        onChange({ target: { value: '2050-12-31' } });
      }
    }
  };

  return (
    <div className={styles.inputContainer} style={containerStyle}>
      <input
        id={id}
        type={type}
        checked={checked}
        className={cn(
          styles.input,
          className,
          error && styles.input_border_error,
          !error && styles.input_border,
        )}
        style={style}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={checktimeval}
        onClick={onClick}
        disabled={disabled}
        readOnly={readOnly}
        min={min ? min : type === 'date' ? '1970-01-01' : '07:00'}
        max={max ? max : type === 'date' ? '2050-12-31' : '18:00'}
        maxLength={maxLength}
        step={step}
        tabIndex={tabIndex}
        ref={ref}
        onKeyDown={onKeyDown ? onKeyDown : () => { }}
        accept={accept}
      />

      {faIcon && (
        <div className={styles.inputIcon} onClick={onFaIconClick}>
          <i className={cn('fa', faIcon)} />
        </div>
      )}
    </div>
  );
}
