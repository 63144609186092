import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'library/common/commonComponents/Loader';
import styles from './employeeCheckInOutHistory.module.scss';
import { getEmployeeWorkingDaysInTimespan, recalculateOT, updateEmployeeInformation } from 'library/api/employee';
import ProfileAvatar from '../../../ProfileAvatar';
import HistoryEntryRow from './HistoryEntryRow';
import {
  formatMilisToTimeString,
  getWorkingTimeForDateInMin,
} from 'library/utilities/employeeCheckInOut';
import { createPDFEmployeeCheckinout } from 'library/utilities/files';
import Button from 'library/common/commonComponents/Buttons/Button';
import DatePicker from 'library/common/commonComponents/DatePicker';
import store from 'main/store/configureStore';
import EditButtons from './EditButtons';
import OvertimeEditHistoryPopup from './OvertimeEditHistoryPopup';

export default function EmployeeCheckInOutHistory({ employee, employeeKitaAbsenceTypes }) {
  const [employeeWorkingDays, setEmployeeWorkingDays] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState(employee?.employeeInformation || null);
  const [todayCheckInOuts, setTodayCheckInOuts] = useState([]);
  const [futureAbsences, setFutureAbsences] = useState([]);
  const [futurePublicHolidays, setFuturePublicHolidays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // set export month to first day of current month
  const [exportMonth, setExportMonth] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );
  const { t } = useTranslation();
  const user = store.getState().userReducer;
  const kitaId = store.getState().kitaReducer.activeKita.kitaId;

  const [showHistory, toggleShowHistory] = useReducer(state => {
    return !state;
  }, false);

  const fetchEmployeeWorkingDays = useCallback(() => {
    const currentExportMonth = new Date(exportMonth);
    currentExportMonth.setHours(0, 0, 0, 0);
    const to = new Date(currentExportMonth.getFullYear(), currentExportMonth.getMonth() + 1, 0);
    to.setHours(23, 59, 59, 999);

    setIsLoading(true);

    getEmployeeWorkingDaysInTimespan({
      userId: employee.user.id,
      from: currentExportMonth.toISOString(),
      to: to.toISOString(),
    })
      .then(({ data }) => {
        setEmployeeWorkingDays(data?.employeeWorkingDays?.length ? data.employeeWorkingDays : []);
        setFutureAbsences(data?.futureAbsences?.length ? data.futureAbsences : []);
        setFuturePublicHolidays(
          data?.futurePublicHolidays?.length ? data.futurePublicHolidays : [],
        );
        setTodayCheckInOuts(data?.todayCheckInOuts);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [exportMonth, employee.user.id]);

  useEffect(() => {
    if (showHistory) {
      fetchEmployeeWorkingDays();
    }
  }, [showHistory, fetchEmployeeWorkingDays]);

  const updateEmployeeOvertime = async overtime => {
    try {
      const newEmployee = await updateEmployeeInformation({
        userId: employee.user.id,
        overtime: overtime,
      });
      setEmployeeInfo(newEmployee.data.employeeInformation);
    } catch (err) {
      console.error(err);
      fetchEmployeeWorkingDays();
    }
  };

  // group all checkin & checkout entries to display in one element
  const getGroupedCheckInOut = workingDay => {
    if (workingDay.checkInOuts?.length > 0) {
      return workingDay.checkInOuts.reduce((accumulator, _, currentIndex, array) => {
        if (currentIndex % 2 === 0) {
          accumulator.push(array.slice(currentIndex, currentIndex + 2));
        }
        return accumulator;
      }, []);
    } else {
      return null;
    }
  };

  const createEmployeeWorkingDayPerDate = employeeWorkingDays => {
    const employeeWorkingDayByDate = employeeWorkingDays.reduce((acc, curr) => {
      const date = new Date(curr.date).toLocaleDateString('de-DE');
      acc[date] = { ...curr, groupedCheckedInOuts: getGroupedCheckInOut(curr) };
      return acc;
    }, {});

    const futureAbsenceByDate = {};
    futureAbsences.forEach(absence => {
      // iterate from startDate to endDate  of absence and add a placeholder working day for each day
      for (
        let date = new Date(absence.startDate);
        date <= new Date(absence.endDate);
        date.setDate(date.getDate() + 1)
      ) {
        futureAbsenceByDate[date.toLocaleDateString('de-DE')] = absence;
      }
    });

    const futurePublicHolidayByDate = {};
    futurePublicHolidays.forEach(holiday => {
      const dateString = new Date(holiday.date).toLocaleDateString('de-DE');
      futurePublicHolidayByDate[dateString] = holiday;
    });

    // create one row for each day
    const allDaysOfMonth = [];
    const to = new Date(exportMonth.getFullYear(), exportMonth.getMonth() + 1, 0);
    for (const date = new Date(exportMonth); date <= to; date.setDate(date.getDate() + 1)) {
      allDaysOfMonth.push(new Date(date.valueOf()).toLocaleDateString('de-DE'));
    }

    const workingDayPerDate = allDaysOfMonth.map(date => {
      let entry;
      if (employeeWorkingDayByDate[date]) {
        entry = employeeWorkingDayByDate[date];
      } else {
        // for days without working day like today and future days
        const isToday = date === new Date().toLocaleDateString('de-DE');
        let todayWorkingTimeInMs = 0;
        if (isToday && employeeInfo !== null) {
          todayWorkingTimeInMs = 1000 * 60 * getWorkingTimeForDateInMin(employeeInfo, new Date());
        }

        entry = {
          employeeAbsence: futureAbsenceByDate[date] ? futureAbsenceByDate[date] : null,
          legalBreak: 0,
          overtime: 0,
          workingTime: todayWorkingTimeInMs,
          timeWorked: 0,
          publicHoliday: futurePublicHolidayByDate[date] ? futurePublicHolidayByDate[date] : null,
          checkInOuts: isToday ? todayCheckInOuts : [],
          groupedCheckedInOuts: isToday
            ? getGroupedCheckInOut({ checkInOuts: todayCheckInOuts })
            : null,
        };
      }
      return {
        date: date,
        entry: entry,
      };
    });
    return workingDayPerDate.reverse();
  };

  const employeeOvertime = !!employeeInfo?.overtime
    ? formatMilisToTimeString(employeeInfo.overtime)
    : '00:00';

    const recalculate = async () => {
      // try {
      //   const res = await recalculateOT(employee.user.id, kitaId);
      //     // Update the overtime value in the state
      //     setEmployeeInfo(prev => ({
      //       ...prev,
      //       overtime: res?.data ? res.data : '00:00', // Assuming the backend returns the new overtime value
      //     }));
    
      //     // Re-fetch employee working days
      //     fetchEmployeeWorkingDays();
      // } catch (err) {
      //   console.error('Error recalculating overtime:', err);
      // }
    };

  return (
    <div>
      <div className={styles.employeeHistory}>
        <div className={styles.wrapper} onClick={toggleShowHistory}>
          <ProfileAvatar className={styles.avatar} id={employee.user.id} withoutLink />
          <div className={styles.name}>{`${employee.user.firstName} ${employee.user.lastName
            }`}</div>

          <div className={styles.middleColumm}>
            <div className={styles.workingTime} onClick={e => e.stopPropagation()}>
              <i className='fa fa-clock-o' />
              <span className={styles.workingTimeLabel}>{t('EmployeeCheckinout.Overtime')}</span>
              <span className={styles.overtimeValue}>{employeeOvertime}</span>
              {user.administrationAccess || user.superAdminStatus ? (
                <>
                  <span className={styles.separator} />
                  <EditButtons
                    value={employeeOvertime}
                    handleSubmit={updateEmployeeOvertime}
                    employeeName={employee.user.firstName + ' ' + employee.user.lastName}
                  />
                  {user && user?.superAdminStatus && <Button
                    size='sm'
                    onClick={() => recalculate()}
                    className={styles.viewOvertimeHistory}
                  >
                    Recalculate Overtime
                  </Button>}
                  <OvertimeEditHistoryPopup userId={employee.user.id} kitaId={kitaId} />
                </>
              ) : null}
            </div>
          </div>

          <div className={styles.expandIcon}>
            <i className={showHistory ? 'fa fa-lg fa-chevron-up' : 'fa fa-lg fa-chevron-down'} />
          </div>
        </div>
        {showHistory && (
          <div className={styles.historyRowsWrapper}>
            <div className={styles.historyHeader}>
              <h3>{t('EmployeeCheckinout.Employee checkin checkout')}</h3>
              <div className={styles.monthSelectionContainer}>
                <Button
                  onClick={() => {
                    setExportMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
                  }}
                  isFullWidth={false}
                  disabled={isLoading}
                >
                  <i className='fa fa-chevron-left' aria-hidden='true' />
                </Button>

                <div className={styles.datePickerWrapper}>
                  <DatePicker
                    selected={exportMonth}
                    onChange={date => setExportMonth(date)}
                    dateFormat='MM/yyyy'
                    dropdownMode='select'
                    showMonthYearPicker
                    showFullMonthYearPicker
                    langCode={user.currentWatsonLang}
                    disabled={isLoading}
                  />
                </div>

                <Button
                  onClick={() => {
                    setExportMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
                  }}
                  isFullWidth={false}
                  disabled={isLoading}
                >
                  <i className='fa fa-chevron-right' aria-hidden='true' />
                </Button>
              </div>

              <div className={styles.seperator} />

              <Button
                onClick={() => {
                  try {
                    createPDFEmployeeCheckinout(
                      employeeWorkingDays,
                      futureAbsences,
                      futurePublicHolidays,
                      employee.user,
                      employeeInfo,
                      exportMonth,
                      employeeKitaAbsenceTypes,
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                type='primary'
                isFullWidth={false}
                className={styles.exportButton}
                disabled={isLoading}
              >
                {t('EmployeeCheckinout.Export monthly report as PDF')}

                <i className='fa fa-download' aria-hidden='true' />
              </Button>
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <>
                {employeeWorkingDays?.length === 0 &&
                  todayCheckInOuts?.length === 0 &&
                  futureAbsences?.length === 0 &&
                  futurePublicHolidays?.length === 0 ? (
                  <p className={styles.noCheckInOutEntries}>
                    {t('EmployeeCheckinout.No CheckInOut entries available')}
                  </p>
                ) : (
                  <>
                    {createEmployeeWorkingDayPerDate(employeeWorkingDays).map(workingDay => {
                      return (
                        <HistoryEntryRow
                          workingDay={workingDay}
                          key={workingDay.date}
                          fetchEmployeeWorkingDays={fetchEmployeeWorkingDays}
                          employeeKitaAbsenceTypes={employeeKitaAbsenceTypes}
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
