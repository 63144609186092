import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './employeeContainer.module.scss';
import { checkInOut, getEmployeeCheckInOutInTimespan } from 'library/api/employee';
import ProfileAvatar from '../../ProfileAvatar';
import Loader from '../../Loader';
import { useSelector } from 'react-redux';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import BreakButton from './BreakButton';
import cn from 'classnames';
import {
  getTotalManualBreakTimeInMilis,
  formatMilisToTimeString,
} from 'library/utilities/employeeCheckInOut';

const EmployeeContainer = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.userReducer);

  const [checkInOutsOfDay, setCheckInOutsOfDay] = useState([]);
  const [mostRecentCheckInOut, setMostRecentCheckInOut] = useState({});
  const [breakTimeMs, setBreakTimeMs] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // for initial loading
  const [isSubmitting, setIsSubmitting] = useState(false); // for submitting checkInOut

  useEffect(() => {
    // get checkInOuts of today
    const from = new Date();
    from.setHours(0, 0, 0, 0);
    const to = new Date();
    to.setHours(23, 59, 59, 999);

    getEmployeeCheckInOutInTimespan({ from: from.toISOString(), to: to.toISOString() })
      .then(res => {
        setCheckInOutsOfDay(res?.data);
        setMostRecentCheckInOut(res?.data[res?.data.length - 1] || {});
        setBreakTimeMs(getTotalManualBreakTimeInMilis(res?.data));
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    let intervalId;
    if (mostRecentCheckInOut.checkInOutCategory === 'BREAK') {
      // run first time without interval to instantly update breakTimeMs and make it more responsive
      setBreakTimeMs(prevBreakTime => prevBreakTime + calculateTimeDifference());
      intervalId = setInterval(() => {
        setBreakTimeMs(prevBreakTime => prevBreakTime + 1000);
      }, 1000);
    } else if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }

    return () => clearInterval(intervalId);
  }, [mostRecentCheckInOut.checkInOutCategory]);

  const calculateTimeDifference = () => new Date() - new Date(mostRecentCheckInOut.actionDate);

  const handleCheckInOutOfEmployee = async (isBreak = false) => {
    if (isLoading || isSubmitting) return;

    if (
      mostRecentCheckInOut.checkInOutType === 'CHECKOUT' &&
      mostRecentCheckInOut.checkInOutCategory !== 'BREAK' &&
      isBreak
    ) {
      store.dispatch(
        showBottomNotification(t("EmployeeCheckinout.Can't pause when checked out"), {
          isFail: true,
        }),
      );
      return;
    }

    const newType = mostRecentCheckInOut.checkInOutType === 'CHECKIN' ? 'CHECKOUT' : 'CHECKIN';
    const newCheckInOut = {
      checkInOutType: newType,
      userId: user.id,
      checkInOutCategory: newType === 'CHECKOUT' && isBreak ? 'BREAK' : null,
    };

    setIsSubmitting(true);

    const fromBeginningOfDay = new Date();
    fromBeginningOfDay.setHours(0, 0, 0, 0);
    const toEndOfDay = new Date();
    toEndOfDay.setHours(23, 59, 59, 999);

    try {
      await checkInOut(newCheckInOut);
      const { data: checkInOutsOfDay } = await getEmployeeCheckInOutInTimespan({
        from: fromBeginningOfDay,
        to: toEndOfDay,
      });
      setCheckInOutsOfDay(checkInOutsOfDay);
      setMostRecentCheckInOut(checkInOutsOfDay[checkInOutsOfDay.length - 1] || {});
      setBreakTimeMs(getTotalManualBreakTimeInMilis(checkInOutsOfDay));
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <button
            className={cn(
              styles.employeeContainer,
              mostRecentCheckInOut.checkInOutType === 'CHECKIN' && styles.nameCheckin,
              mostRecentCheckInOut.checkInOutType === 'CHECKOUT' && styles.nameCheckout,
              mostRecentCheckInOut.checkInOutType === 'BREAK' && styles.namePause,
              checkInOutsOfDay?.length === 0 && styles.nameCheckout, // default is checkedout
            )}
            onClick={() => handleCheckInOutOfEmployee(false)}
          >
            <ProfileAvatar className={styles.photo} id={user.id} withoutLink />

            <div className={styles.name}>
              {`${user.firstName} ${user.lastName}`}
              <div className={styles.checkInOutBadgeWrapper}>
                <div className={styles.checkInOutBadge}>
                  {mostRecentCheckInOut.checkInOutType === 'CHECKIN' && t('Checkinout.IsCheckedIn')}
                  {mostRecentCheckInOut.checkInOutType === 'CHECKOUT' &&
                    t('Checkinout.IsCheckedOut')}
                  {mostRecentCheckInOut.checkInOutType === 'BREAK' && t('EmployeeCheckinout.Pause')}
                  {/* default is checkedout */}
                  {checkInOutsOfDay.length === 0 && t('Checkinout.IsCheckedOut')}
                </div>
              </div>
            </div>
          </button>

          <BreakButton
            active={mostRecentCheckInOut.checkInOutCategory === 'BREAK'}
            handleClick={() => handleCheckInOutOfEmployee(true)}
            breakTime={formatMilisToTimeString(breakTimeMs, true, true)}
          />
        </div>
      )}
    </div>
  );
};

export default EmployeeContainer;
