import React from 'react';
import styles from './employeeAbsenceExport.module.scss';
import { useTranslation } from 'react-i18next';
import { downloadEmployeeAbsenceExport } from 'library/api/employee';
import { useDropdown } from 'library/common/commonHooks/useDropdown';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';

/**
 *
 * @param {Object} param0
 * @param {Number} param0.selectedMonth
 * @param {Number} param0.selectedYear
 * @returns
 */
const EmployeeAbsenceExport = ({ selectedMonth, selectedYear }) => {
  const { t } = useTranslation();
  const { isOpened, DropdownWrapper } = useDropdown(Dropdown);

  const exportAbsences = async format => {
    downloadEmployeeAbsenceExport(
      format,
      `${selectedMonth.toString().padStart(2, 0)}.${selectedYear}`,
    );
  };

  const dropdownOptions = [
    {
      faIcon: 'fa-file-pdf-o',
      title: 'Calendar.exportEmployeeAbsencesAsPdf',
      onClick: () => exportAbsences('pdf'),
    },
    {
      faIcon: 'fa-file-excel-o',
      title: 'Calendar.exportEmployeeAbsencesAsExcel',
      onClick: () => exportAbsences('xlsx'),
    },
  ];

  return (
    <div className={styles.actionsContainer}>
      <div className={styles.action} title={t('Calendar.exportEmployeeAbsences')}>
        <DropdownWrapper
          dropdownOptions={dropdownOptions}
          closeOnClick
          theme='dark'
          render={dropdown => (
            <div className={styles.moreBtn}>
              <i className='fa fa-address-book' />
              {dropdown}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default EmployeeAbsenceExport;
