import Button from 'library/common/commonComponents/Buttons/Button';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Storage from 'library/utilities/storage';
import Input from 'library/common/commonComponents/Inputs/Input';
import { saveOrUpdateKitaPortalSettingsForTraeger, getKitaPortalSettingsForTraeger, importKitaportalForCurrentKita } from 'library/api/user';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';

export default function KitaportalSettingsFrame() {
  const { t } = useTranslation();

  const [kitaPortalSettingsForTraeger, setKitaportalSettingsForTraeger] = useState({
    url: '',
    protocol: '',
    traegerName: '',
    interfacePassword: '',
    certificatePassword: '',
    externalId: '',
    filePath: null,
  });

  const [certificateFile, setCertificateFile] = useState(null);

  useEffect(() => {
    getKitaPortalSettingsForTraeger().then(data => {
      if (data.data) {
        setKitaportalSettingsForTraeger(data.data);
      }
    })
  }, [])

  return (<>
    <h2>Kitaportal Einstellungen</h2>
    <br />
    <table>
      <tr>
        <td>URL</td>
        <td>
          <Input
            type='text'
            maxLength={500}
            value={kitaPortalSettingsForTraeger.url}
            onChange={({ target }) => {
              setKitaportalSettingsForTraeger(prev => ({
                ...prev,
                url: target.value,
              }));
            }}
          />
        </td>
      </tr>
      <tr>
        <td>Protokoll</td>
        <td>
          <Input
            type='text'
            maxLength={500}
            value={kitaPortalSettingsForTraeger.protocol}
            onChange={({ target }) => {
              setKitaportalSettingsForTraeger(prev => ({
                ...prev,
                protocol: target.value,
              }));
            }}
          />
        </td>
      </tr>
      <tr>
        <td>Träger Name</td>
        <td>
          <Input
            type='text'
            maxLength={500}
            value={kitaPortalSettingsForTraeger.traegerName}
            onChange={({ target }) => {
              setKitaportalSettingsForTraeger(prev => ({
                ...prev,
                traegerName: target.value,
              }));
            }}
          />
        </td>
      </tr>
      <tr>
        <td>Externe Id Einrichtung</td>
        <td>
          <Input
            type='text'
            maxLength={500}
            value={kitaPortalSettingsForTraeger.externalId}
            onChange={({ target }) => {
              setKitaportalSettingsForTraeger(prev => ({
                ...prev,
                externalId: target.value,
              }));
            }}
          />
        </td>
      </tr>
      <tr>
        <td>Schnittstellen-Passwort</td>
        <td>
          <Input
            type='password'
            maxLength={500}
            value={kitaPortalSettingsForTraeger.interfacePassword}
            onChange={({ target }) => {
              setKitaportalSettingsForTraeger(prev => ({
                ...prev,
                interfacePassword: target.value,
              }));
            }}
          />
        </td>
      </tr>
      <tr>
        <td>Zertifikats-Passwort</td>
        <td>
          <Input
            type='password'
            maxLength={500}
            value={kitaPortalSettingsForTraeger.certificatePassword}
            onChange={({ target }) => {
              setKitaportalSettingsForTraeger(prev => ({
                ...prev,
                certificatePassword: target.value,
              }));
            }}
          />
        </td>
      </tr>
      {kitaPortalSettingsForTraeger.filePath === null ?
        <tr>
          <td>Zertifikat</td>
          <td>
            <Input
              type='file'
              accept='.p12'
              maxLength={500}
              onChange={({ target }) => {
                setCertificateFile(target.files[0]);
              }}
            />
          </td>
        </tr> :
        <tr>
          <td>Zertifikat</td>
          <td>
            <Button onClick={() => {
              setKitaportalSettingsForTraeger(prev => ({
                ...prev,
                filePath: null,
              }));
            }}>Zertifikat löschen</Button>
          </td>
        </tr>
      }
    </table>
    <br />
    <Button onClick={() => {
      const formData = new FormData();
      let settingsTemp = kitaPortalSettingsForTraeger;
      delete settingsTemp.filePath;
      formData.append("data", JSON.stringify({ ...settingsTemp }));
      formData.append("file", certificateFile);
      saveOrUpdateKitaPortalSettingsForTraeger(formData)
        .then(() => {
          store.dispatch(
            showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
              isFail: false,
            }),
          );
        })
        .catch(() => {
          store.dispatch(
            showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
              isFail: true,
            }),
          );
        })
    }}>Speichern</Button>
    <br />
    <br />
    <hr />
    <br />
    <Button onClick={() => {
      importKitaportalForCurrentKita()
        .then(() => {
          store.dispatch(
            showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
              isFail: false,
            }),
          );
        })
        .catch(() => {
          store.dispatch(
            showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
              isFail: true,
            }),
          );
        })
    }}>Kitaportal importieren</Button>
  </>);
}