import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isAllowedToSendMessages } from 'library/api/chat';
import { isAwoWW } from 'library/api/tenantConfig';
import ChatListItem from 'library/common/commonComponents/Chat/ChatList/ChatListFrames/ChatListItem';
import Loader from 'library/common/commonComponents/Loader';
import CardDropdown from 'library/common/commonComponents/CardDropdown';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import { filterIsNotOnlyPublicKitaMember } from 'library/utilities/kitaChecks';

import styles from '../toolbarNotificationsDropdown.module.scss';

export default function DropdownMessages({
  isOpened,
  isLoadingChatlists,
  onChatClick,
  setIsCreateNewChatOpened,
  setIsCreateNewAbsenceOpened,
  isKitaVirtual,
  kitas,
  currentWatsonLang,
  modules,
  user,
  handleMarkAllAsRead,
  unreadMessagesCountsKitas,
  setUnreadMessagesCountsKitas,
  activeKita,
  timeRangeAbsenceInfo,
  onDeactiveTimeRangeAbsence,
  activeTab,
  setActiveTab,
  shouldDisplayFilterButton,
}) {
  console.log('isLoadingChatlists', isLoadingChatlists)
  const chatsList = useSelector(state => state.chatListPopupReducer.chatsListTopBar);
  const { t } = useTranslation();
  const isEmployee = user.employee;

  const handleReadAllClick = () => {
    handleMarkAllAsRead(chatsList);
  };
  const handleOnChatClick = clickedChat => {
    const isRead = clickedChat.lastMessage.read;
    if (!isRead) {
      setUnreadMessagesCountsKitas({
        ...unreadMessagesCountsKitas,
        [activeKita.kitaId]: unreadMessagesCountsKitas[activeKita.kitaId] - 1,
      });
    }
    onChatClick(clickedChat.id);
  };

  const handleSetTimeRangeAbsence = () => {
    if (timeRangeAbsenceInfo.timeRangeAbsence) {
      onDeactiveTimeRangeAbsence();
    } else {
      setIsCreateNewAbsenceOpened(true);
    }
  };

  return (
    isOpened && (
      <div className={cn(styles.dropdown, styles.dropdownMessages)}>
        <CardDropdown
          header={
            <div className={styles.title}>
              {t('Header.Topbar.Messages.Messages')}
              <div className={styles.addNewChatContainer} onClick={e => e.stopPropagation()}>
                {!isKitaVirtual &&
                  filterIsNotOnlyPublicKitaMember(kitas) &&
                  isAllowedToSendMessages(modules, user) && (
                    <>
                      {((!isAwoWW() && isEmployee) ||
                        (isAwoWW() && (user.administrationAccess || user.superAdminStatus))) && (
                        <ButtonWithLoader
                          type='primary'
                          isLoading={timeRangeAbsenceInfo.isLoading}
                          className={cn(
                            styles.createButton,
                            timeRangeAbsenceInfo.isLoading ? styles.createButtonLoading : null,
                          )}
                          onClick={handleSetTimeRangeAbsence}
                        >
                          {!timeRangeAbsenceInfo.timeRangeAbsence &&
                            !timeRangeAbsenceInfo.isLoading && <i className='fa fa-plus' />}
                          {t(
                            timeRangeAbsenceInfo.timeRangeAbsence
                              ? 'Chat.DiactiveAbsence'
                              : 'Chat.NewAbsence',
                          )}
                        </ButtonWithLoader>
                      )}
                      <Button
                        onClick={() => setIsCreateNewChatOpened(true)}
                        className={styles.createButton}
                      >
                        <i className='fa fa-plus' />
                        {t('Chat.New message')}
                      </Button>
                    </>
                  )}
              </div>
              <div className={styles.markAllAsRead} onClick={handleReadAllClick}>
                {t('Header.Topbar.Notifications.Mark all as read')}
              </div>
            </div>
          }
          footer={
            <Link
              to={{
                pathname: '/chat/1',
                state: { selectedChatId: 0 },
              }}
            >
              <Button className={cn(styles.button, styles.buttonDefault)} isFullWidth>
                {t('Header.Topbar.Messages.Show all messages')}
              </Button>
            </Link>
          }
        >
          <div className={styles.arrow} />
          <div>
            {shouldDisplayFilterButton && (
              <div className={styles.filterContainer}>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    if (isLoadingChatlists) {
                      return;
                    }
                    setActiveTab(0);
                  }}
                  className={activeTab === 0 ? styles.active : null}
                >
                  {t('Chat.Personal')}
                </div>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    if (isLoadingChatlists) {
                      return;
                    }
                    setActiveTab(1);
                  }}
                  className={activeTab === 1 ? styles.active : null}
                >
                  {t('Administration.Absence')}
                </div>
              </div>
            )}
          </div>
          {isLoadingChatlists ? (
            <Loader />
          ) : (
            <div className={styles.messagesContainer}>
              {chatsList.length > 0 ? (
                chatsList.map(item => (
                  <Link
                    key={item.id}
                    to={{
                      pathname: '/chat/1',
                    }}
                  >
                    <ChatListItem
                      isActive={false}
                      onClick={() => handleOnChatClick(item)}
                      lastMessage={item.lastMessage}
                      chatName={item.subject}
                      userList={item.userList || []}
                      id={item.id}
                      isSuggestionBox={item.suggestionBox}
                      isAnonymous={item.anonymousCreator}
                      chatSubjectLanguage={item.watsonLanguage}
                      currentWatsonLang={currentWatsonLang}
                      avatars={false}
                    />
                  </Link>
                ))
              ) : (
                <div className={styles.emptyContainer}>{t('Chat.There are no messages yet')}</div>
              )}
            </div>
          )}
        </CardDropdown>
      </div>
    )
  );
}
