import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import FeedItem from 'library/common/commonComponents/Feed/feedFrames/feedItem/';

import styles from './calendarEvent.module.scss';

export default function CalendarEvent({
  isOpened,
  closePopup,
  eventData,
  updateEvent,
  deleteEvent,
  groupInfo,
  onEdit,
  user,
  match,
  calendarModulePlace,
  onHideDay,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleEdit = async data => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onEdit(data);
    setIsSubmitting(false);
  };

  const history = useHistory();

  const feedItemEventProps = {
    isTranslationAllowed: eventData.isTranslationAllowed,
    watsonLanguage: eventData.watsonLanguage,
    text: eventData.title,
    comments: eventData.comments,
    likes: eventData.likes || [],
    updateFeedById: (id, newValues) =>
      updateEvent({
        ...newValues,
        isPublic: newValues.privatePost === undefined ? eventData.isPublic : !newValues.privatePost,
        canceled: newValues.canceled === undefined ? eventData.canceled : newValues.canceled,
        id,
      }),
    id: eventData.id,
    user,
    firstName: eventData.firstName,
    lastName: eventData.lastName,
    userId: eventData.editable && (eventData.userId || eventData.user.id),
    files: eventData.files,
    privatePost: !eventData.isPublic,
    postType: eventData.postType,
    logoUrl: eventData.user ? eventData.user.logoUrl : '',
    profileLogoXCoordinate: eventData.user ? eventData.user.profileLogoXCoordinate : 0,
    profileLogoYCoordinate: eventData.user ? eventData.user.profileLogoYCoordinate : 0,
    profileLogoWidth: eventData.user ? eventData.user.profileLogoWidth : 0,
    profileLogoAspect: eventData.user ? eventData.user.profileLogoAspect : 0,
    name: eventData.user
      ? `${eventData.user.firstName} ${eventData.user.lastName}`
      : `${eventData.firstName} ${eventData.lastName}`,
    createdAt: eventData.postType === 2 ? eventData.eventCreatedDate : eventData.taskCreatedDate,
    editedAt:
      eventData.postType === 2
        ? eventData.eventUpdatedDate || eventData.eventCreatedDate
        : eventData.taskUpdatedDate,
    userlink: `/profile/${eventData.userId || eventData.user.id}`,
    postlink: '#',
    onDelete: () => deleteEvent(eventData),
    canceled: eventData.canceled,
    eventType: eventData.eventType,
    group: groupInfo || (eventData.group && eventData.group.groupId ? eventData.group : null),
    start: eventData.start,
    end: eventData.end,
    startTime: eventData.startTime,
    endTime: eventData.endTime,
    description: eventData.description,
    color: eventData.color,
    calendarKidParticipations: eventData.calendarKidParticipations || [],
    acceptance: eventData.acceptance,
    maxNumberOfParticipants:
      eventData.participationInfo && eventData.participationInfo.maxNumberOfParticipants,
    participationMode: eventData.participationInfo && eventData.participationInfo.participationEnum,
    participationStateMaybeAllowed:
      eventData.participationInfo && eventData.participationInfo.isAllowMaybe,
    participationStateDeclineAllowed:
      eventData.participationInfo && eventData.participationInfo.isAllowDecline,
    inCalendarModule:
      eventData.group && eventData.group.groupId ? calendarModulePlace : 'userProfileCalendar',
    onEventEdit: handleEdit,
    isPublic: eventData.isPublic,
    taskStatus: eventData.taskStatus,
    title: eventData.title,
    taskListId: eventData.taskListId,
    isScheduling: eventData.isScheduling,
    allDay: eventData.allDay,
    addToCalendar: eventData.addToCalendar,
    assignedUsersList: eventData.assignedUsersList,
    responsibleUsersList: eventData.responsibleUsersList,
    reviewRequired: eventData.reviewRequired,
    checklist: eventData.checklist,
    taskList: eventData.taskList,
    match,
    location: eventData.location,
    streetMap: eventData.streetMap,
    notify: eventData.notify,
    hideDay: eventData.hideDay,
    onHideDay: onHideDay,
    publicVisible: eventData.publicVisible,
    userParticipations: eventData.userParticipations,
    eventClickInfo: eventData.eventClickInfo,
  };

  const { t } = useTranslation();

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      size='large'
      showHeader={false}
      footer={
        <div className={styles.buttons}>
          {eventData.editable && eventData.postType !== 5 && (
            <ButtonWithLoader
              onClick={handleEdit}
              type='primary'
              className={styles.button}
              isLoading={isSubmitting}
            >
              {t('Post.Edit')}
            </ButtonWithLoader>
          )}
          {eventData.postType === 5 && (
            <Button
              type='primary'
              className={styles.button}
              onClick={() => {
                const groupId = eventData.group.groupId;
                history.push(`/groups/${groupId}/aufgaben`);
              }}
            >
              {t('CalendarEventPopup.GoToTasks')}
            </Button>
          )}
          <Button onClick={closePopup}>{t('Popup.Close')}</Button>
        </div>
      }
      body={<FeedItem {...feedItemEventProps} />}
    />
  );
}
