import React from 'react';


import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';


import styles from './kitalinoFrame.module.scss';

export default function KitalinoFrame() {

  return (
    <div className={styles.container}>

      <iframe
        className={styles.iframecl}
        src={"https://kitalino.com"}
        title='Hilfe'
      />
    </div>
  );
}
